import { Stack, Typography, Box, Button, Alert } from "@mui/material";
import { MissingFields } from "../assets";

const MissingField = ({ navigate }) => {
  return (
    <>
      <Alert
        severity="error"
        action={
          <Button
            onClick={() => {
              navigate("/profile/account");
            }}
            color="primary"
            size="small"
          >
            Go to profile page
          </Button>
        }
      >
        You have missing information in your profile. You need to fill this to
        proceed.
      </Alert>

      <Stack spacing={1} m={1} mt={5}>
        <Typography
          variant={"h3"}
          textAlign={"center"}
          color={"primary"}
          fontWeight={"bold"}
        >
          {"Oops! Missing Information"}
        </Typography>
        <Typography variant={"body1"} textAlign={"center"} color={"primary"}>
          {
            "Kindly fill all mandatory fileds. Profile must be complete access the app."
          }
        </Typography>
      </Stack>
      <Stack flex={1} spacing={3}>
        <Box
          component={"img"}
          src={MissingFields}
          sx={{
            height: { xs: 300, sm: 300, md: 400, lg: 400, xl: 700 },
          }}
          loading={"lazy"}
        />
      </Stack>
    </>
  );
};

export default MissingField;
