import { SvgIcon } from "@mui/material";
import { ReactComponent as TimelapseIcon } from '../icons/ic_timelapse.svg';

function Timelapse(props){
    return  <SvgIcon
    {...props}
    component={TimelapseIcon}
    inheritViewBox
  />
}

export default Timelapse