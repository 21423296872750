import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";

const PrimaryButton = (props) => (
  <LoadingButton
    {...props}
    disableElevation
    variant={"contained"}
    color={"primary"}
    sx={{
      display: "block",
      borderRadius: "20px",
      width: "fit-content",
      px: 5,
      textTransform: "initial",
      bgcolor: props.bg && props.bg,
    }}
  >
    {props.children}
  </LoadingButton>
);

const TextButton = (props) => (
  <Button
    {...props}
    sx={{
      color: props.color ?? "black",
      textAlign: "start",
      width: "fit-content",
      textTransform: "initial",
      fontWeight: props.bold ? "bold" : "inherit",
    }}
    variant="text"
  >
    {props.children}
  </Button>
);

export { PrimaryButton, TextButton };
