import { collection, onSnapshot } from "firebase/firestore";
import { auth, db } from "../firebase";
import { eventConverter } from "../models/eventModel";
import { init, saveData } from "./eventSlice";

const updateEvents = () => {
  return (dispatch) => {
    dispatch(init());

    onSnapshot(
      collection(db, "cms/website/events").withConverter(eventConverter),
      (querySnapshot) => {
        const resources = [];
        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          // console.log("Dsds", docData);
          if (!docData.disabled) {
            resources.push({ ...docData, id: doc.id });
          }
          // resources.push({ ...doc.data(), id: doc.id });
        });
        // if (auth.currentUser) {
        dispatch(saveData(resources));
        // }
        // else {
        //   const onlyGeneralEvents = resources.filter(
        //     (resource) => resource.type === "general"
        //   );
        //   dispatch(saveData(onlyGeneralEvents));
        // }
      }
    );
  };
};

export { updateEvents };
