export class ElectionModel {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.id = obj.id != null ? obj.id : "";
    this.candidate_name = obj.candidate_name != null ? obj.candidate_name : "";
    this.election_type = obj.election_type != null ? obj.election_type : "";
    this.img_url = obj.img_url != null ? obj.img_url : "";
    this.from = obj.from != null ? obj.from : "";
    this.to = obj.to != null ? obj.to : "";
    this.date = obj.date != null ? obj.date : "";
    this.has_image = obj.has_image != null ? obj.has_image : false;
  }
}

export const electionsConverter = {
  toFirestore: (election) => {
    return {
      id: election.id,
      candidate_name: election.candidate_name,
      election_type: election.election_type,
      from: election.from,
      to: election.to,
      img_url: election.img_url,
      date: election.date,
      has_image: election.has_image,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new ElectionModel({
      id: data.id,
      candidate_name: data.candidate_name,
      election_type: data.election_type,
      from: data.from,
      to: data.to,
      img_url: data.img_url,
      date: new Date(data?.date?.seconds * 1000).toLocaleDateString(),
      has_image: data.img_url !== "",
    });
  },
};
