import { Banner, JobCard, Newsletter, Title } from "../../components/index";
import {
  Box,
  Stack,
  Typography,
  CssBaseline,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React from "react";
import { placeHolderText } from "../../constants";
import { useSelector } from "react-redux";
import { ExpandMore } from "../../assets";

const Content = () => {
  const [value, setValue] = React.useState(0);
  const { data: jobs } = useSelector((state) => state.job);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Stack
      spacing={6}
      flexGrow={1}
      sx={{ width: "100%", py: 6, px: { xs: "15px", md: "80px" } }}
    >
      <Stack direction={"row"} flexGrow={1} spacing={{ xs: 0, md: 6 }}>
        <Box flex={{ xs: 1, md: jobs.length === 0 ? 1 : 0.8 }}>
          <Stack
            sx={{
              borderTop: "1px solid #707070",
              borderBottom: "1px solid #707070",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{ style: { background: "#F30813" } }}
            >
              <Tab label={"All"} />
              <Tab label={"Policy"} />
              <Tab label={"Regulatory"} />
            </Tabs>
          </Stack>
          <Stack sx={{ my: 4 }} spacing={4}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
              >
                <Typography fontWeight={"bold"}>
                  Why am I unable to view some pages?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  When you are unable so view some pages, specifically the GAMLS
                  Fund, Learning, and Jobs, it means your account has not been
                  activated yet by the administrator. When this happens you can
                  contact your local executives or reach out to the admin via
                  admin.gamls.org
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
              >
                <Typography fontWeight={"bold"}>
                  When I click on certain links, I get page under construction,
                  why?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We are working tirelessly to bring you the best of experience.
                  Pages under construction will be vaialble in no time. Worth
                  the wait!
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Box>
        {jobs.length !== 0 && (
          <Box
            sx={{
              display: { xs: "none", md: "inherit" },
              flexDirection: "column",
            }}
            flex={0.2}
          >
            <Title title={"Jobs"} underline />
            <JobCard
              data={{
                location: "Kasoa Polyclinic",
                title: "Job Tittle",
                desc: placeHolderText.medium,
                region: "Greater Accra",
                category: "Full Time",
                date: "Fri, Jun 23, 2022",
                salary: "¢2500",
              }}
            />
          </Box>
        )}
      </Stack>
      <Newsletter />
    </Stack>
  );
};

const Faq = () => {
  const bannerProps = {
    title: "Frequently Asked Questions",
    subTitle: "Find the right answers to most of your asked questions!",
  };
  return (
    <>
      <CssBaseline />
      <Banner {...bannerProps} />
      <Content />
    </>
  );
};
export default Faq;
