export function mapAuthCodeToMessage(authCode) {
  switch (authCode) {
    case "auth/invalid-password":
      return "Invalid email or password";

    case "auth/invalid-email":
      return "Invalid email or password";

    case "auth/missing-password":
      return "Password is required";

    case "auth/network-request-failed":
      return "Network error occured";

    case "auth/user-not-found":
      return "User not found";
    case "auth/wrong-password":
      return "You entered a wrong password. Kindly retry";

    case "auth/email-already-in-use":
      return "The email provided is associated with an existing user. Kindly provide a new email address.";

    default:
      return "Please supply needed info or try again later";
  }
}
