import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent, Divider, Modal, Stack } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import { grey } from "@mui/material/colors";
import { LoadingButton } from "@mui/lab";
import { setNotifRead } from "../data/firebase";
import { useSelector } from "react-redux";

export const PrimaryButton = ({ text = "Submit", ...props }) => (
  <LoadingButton
    variant={"contained"}
    disableElevation
    sx={{
      width: "180px",
      height: "48px",
      bgcolor: "black",
      textTransform: "capitalize",
      fontSize: 18,
      borderRadius: 5,
      my: 6,
      px: 7,
      py: 4,
      fontWeight: "bold",
      whiteSpace: "nowrap",
    }}
    type={"submit"}
    {...props}
  >
    {text}
  </LoadingButton>
);

export default function CustomAccordion({ notification }) {
  const [open, setOpen] = React.useState(false);
  const { user: localUser } = useSelector((state) => state.auth);

  const handleOpen = (id) => {
    setOpen(true);
    setNotifRead(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const readIndicator = () => {
    if (
      Array.isArray(notification.readBy) &&
      notification.readBy.includes(localUser.email)
    ) {
      return <CheckCircleOutlineIcon color={"primary"} sx={{ fontSize: 30 }} />;
    } else {
      return (
        <RadioButtonUncheckedOutlinedIcon
          color={"black"}
          sx={{ fontSize: 30 }}
        />
      );
    }
  };

  return (
    <>
      <Card
        onClick={() => handleOpen(notification.id)}
        elevation={0}
        sx={{
          width: "93%",
          bgcolor: grey[100],
          borderBottom: `1px solid ${grey[300]}`,
          px: 3,

          transition: "background-color 0.3s", // Smooth transition on color change
          "&:hover": {
            backgroundColor: grey[300], // Change color on hover
          },
        }}
      >
        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            // spacing={2}
          >
            <Typography variant="h6" component="div" fontWeight={"bold"}>
              {notification.title}
            </Typography>

            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="body1" component="div" fontWeight={"bold"}>
                {notification.date}
              </Typography>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ bgcolor: "black", width: 5 }}
              />
              <Stack direction="row" alignItems="center" spacing={1}>
                {readIndicator()}

                <Typography
                  variant="body2"
                  component="div"
                  fontWeight={"lighter"}
                >
                  Read
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
        {/* <CardActions>
      <Button size="small">Learn More</Button>
    </CardActions> */}
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="notification-modal-title"
        aria-describedby="notification-modal"
      >
        <Card
          sx={{
            position: "absolute",
            top: "35%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            boxShadow: 24,
            p: 4,
            width: "60%",
            minHeight: "30%",
            overflowY: "auto",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            my={5}
          >
            <Typography variant="h5" fontWeight={"bold"} component="h2">
              {notification.title}
            </Typography>
            <Typography variant="h6" fontWeight={"lighter"} component="h2">
              {notification.date}
            </Typography>
          </Stack>
          <Typography fontWeight={"lighter"} variant="h6" component="h2">
            {notification.message}
          </Typography>
          <Stack alignItems="center">
            <PrimaryButton
              onClick={handleClose}
              text={"Close"}
              // disabled={formik.isSubmitting}
              // loading={formik.isSubmitting}
            />
          </Stack>
        </Card>
      </Modal>
    </>
  );
}
