import { ArrowForwardIos, Book, ExpandLess, ExpandMore } from "../../assets";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import LearningComplete from "./learningComplete";
import LearningHook from "./learningHook";
import { Interweave } from "interweave";
import empty from "../../assets/json/empty.json";
import Lottie from "lottie-react";

export const LearningDetails = ({ _learningHook = LearningHook() }) => {
  const {
    values,
    handleChange,
    handleNext,
    handleSubmit,
    open,
    handleClick,
    courses,
    handleCourseClick,
    activeLesson,
    courseCompleted,
    lessonsLoading,
  } = _learningHook;

  const { description, video_url, questions, name, course: c } = activeLesson;
  const embedable = video_url?.includes("embed")
    ? video_url
    : `https://www.youtube.com/embed/${video_url?.split("v=")[1]}`;

  //console.log(values);

  return courseCompleted ? (
    <LearningComplete hook={_learningHook} />
  ) : (
    <>
      <Stack
        flex={1}
        direction={{ xs: "column", md: "row" }}
        bgcolor={"#F4F4F4"}
      >
        <Stack
          flex={0.15}
          sx={{
            zIndex: { xs: 1, md: 0 },
          }}
        >
          <Drawer
            variant="permanent"
            sx={{
              width: { xs: "100%", md: 300 },
              [`& .MuiDrawer-paper`]: {
                width: { xs: "100%", md: 300 },
                height: "fit-content",
                borderBottom: { xs: "1px solid #eeee", md: "none" },
                border: "none",
              },
            }}
          >
            <Toolbar sx={{ height: { xs: 136, md: 200 } }} />
            <Box sx={{ overflow: "auto" }}>
              <List>
                <ListItemButton onClick={handleClick}>
                  <ListItemText
                    primary="Course Outline"
                    primaryTypographyProps={{
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                {open ? <Divider /> : null}
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {courses?.map((course, index) => (
                      <ListItem key={course.name} disablePadding>
                        <ListItemButton
                          onClick={() => handleCourseClick(course.name)}
                        >
                          <ListItemIcon>
                            <Book />
                          </ListItemIcon>
                          <ListItemText
                            primary={course.name}
                            secondary={
                              courses[index].name.toLowerCase() === c
                                ? "In progres"
                                : ""
                            }
                            // secondaryTypographyProps={{
                            //   color: course.status > 0 ? "green" : "inherit",
                            // }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </List>
            </Box>
          </Drawer>
        </Stack>
        <Stack
          spacing={3}
          flex={{ xs: 1, md: 0.7 }}
          sx={{ py: { xs: 6, md: 6 }, px: { xs: "15px", md: "140px" } }}
          mt={{ xs: 5, md: 0 }}
        >
          <Typography variant="h4" fontWeight={"bold"} component={"div"}>
            {lessonsLoading ? <Skeleton /> : name}
          </Typography>

          {lessonsLoading ? (
            <Skeleton variant="rectangular" width={"100%"} height={500} />
          ) : (
            <Box
              className="video-container"
              sx={{ display: video_url ? "inherit" : "none" }}
            >
              <Box
                component={"iframe"}
                title={"course video"}
                src={embedable}
                frameborder="0"
                allowfullscreen
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                sx={{
                  width: "100%",
                  height: 500,
                }}
              />
            </Box>
          )}

          {lessonsLoading ? (
            <Skeleton variant="text" />
          ) : (
            <Box sx={{ fontSize: 16 }}>
              <Interweave content={description} />
            </Box>
          )}
          {activeLesson.id ? (
            <Stack>
              <Typography variant="h6">
                Answer Questions to Grade yourself
              </Typography>
              <List
                component={Paper}
                sx={{
                  bgcolor: "#E5E5E5",
                  p: 3,
                  borderRadius: 2,
                }}
                elevation={0}
              >
                <ListItemText
                  primary={"Why is it important to answer these questions?"}
                />
                {[
                  "You’ll significantly improve your ability to remember what you’ve just learnt",
                  "You’ll significantly improve your ability to remember what you’ve just learnt",
                  "You’ll significantly improve your ability to remember what you’ve just learnt",
                  "You’ll significantly improve your ability to remember what you’ve just learnt",
                ].map((x, idx) => (
                  <ListItemText
                    key={idx}
                    primary={`${idx + 1}. ${x}`}
                    sx={{ mb: 1 }}
                  />
                ))}
              </List>
            </Stack>
          ) : (
            <Lottie animationData={empty} />
          )}

          <Stack spacing={3}>
            {activeLesson.id ? (
              <Typography fontWeight={"bold"}>
                Answer All questions to proceed to the next lesson.
              </Typography>
            ) : null}
            {(lessonsLoading ? Array.from(new Array(3)) : questions).map(
              (question, idx) => {
                const value = values.length === 0 ? "" : values[idx];
                return (
                  <Stack
                    key={question?.question}
                    spacing={1}
                    component={"form"}
                    onSubmit={(e) => handleSubmit(idx, e)}
                    name={question?.name}
                  >
                    <Typography
                      component={"div"}
                      variant="h6"
                      fontWeight={"bold"}
                    >
                      {question ? `Question ${idx + 1}` : <Skeleton />}
                    </Typography>

                    <FormControl
                      error={value?.error ? value?.error : false}
                      variant="standard"
                      name={question?.question}
                      id={idx}
                      component={Stack}
                      spacing={2}
                    >
                      {question ? (
                        <FormLabel id="demo-controlled-radio-buttons-group">
                          {question?.question}
                        </FormLabel>
                      ) : (
                        <Skeleton varinat={"text"} />
                      )}
                      {question ? (
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          id={idx}
                          name={question?.question}
                          value={value?.id}
                          onChange={(e) => handleChange(idx, e)}
                        >
                          {question?.questionChoices &&
                            question?.questionChoices.map((x) => (
                              <FormControlLabel
                                name={x?.answer}
                                key={x?.id}
                                value={x?.answer}
                                control={<Radio />}
                                label={x?.answer}
                                sx={{ mb: 1 }}
                              />
                            ))}
                        </RadioGroup>
                      ) : (
                        <Skeleton variant={"rectangular"} height={200} />
                      )}
                      <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems={"center"}
                      >
                        {question ? (
                          <Button
                            variant={"contained"}
                            type={"submit"}
                            disableElevation
                            sx={{
                              width: "fit-content",
                              bgcolor: "black",
                              borderRadius: 2,
                            }}
                          >
                            Submit answer
                          </Button>
                        ) : (
                          <Skeleton variant="rounded" width={80} height={40} />
                        )}
                        {question ? (
                          <FormHelperText
                            sx={{ color: value?.error ? "inherit" : "green" }}
                          >
                            {value?.helperText
                              ? value?.helperText
                              : "Please select an option"}
                          </FormHelperText>
                        ) : (
                          <Skeleton variant={"text"} width={80} />
                        )}
                      </Stack>
                    </FormControl>
                  </Stack>
                );
              }
            )}
          </Stack>
          <Divider />
          <Button
            disableElevation
            disabled={!activeLesson.id}
            variant={"contained"}
            sx={{
              width: "fit-content",
              alignSelf: "flex-end",
              borderRadius: 2,
            }}
            endIcon={<ArrowForwardIos />}
            onClick={handleNext}
          >
            Next Lesson
          </Button>
        </Stack>
        <Stack
          flex={0.15}
          sx={{ display: { xs: "none", md: "inherit" } }}
        ></Stack>
      </Stack>
    </>
  );
};

export default LearningDetails;
