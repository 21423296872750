import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { CloudDownload } from "@mui/icons-material";
import { Button } from "@mui/material";

import { GamlsCert } from "../assets";

const styles = StyleSheet.create({
  page: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
  },

  fullName: {
    position: "absolute",
    color: "black",
    fontWeight: "bold",
    top: 260,
    left: 0,
    right: 0,
    fontSize: 32,
    textAlign: "center",
  },
  memberId: {
    position: "absolute",
    color: "black",
    fontWeight: "bold",
    top: 40,
    right: 20,
    fontSize: 14,
  },
  certId: {
    position: "absolute",
    color: "black",
    fontWeight: "bold",
    top: 515,
    right: 7,
    fontSize: 13,
  },
});

// Create the PDF document component
const CertFormat = ({ fullName, memberId, certId }) => (
  <>
    {JSON.stringify(fullName, memberId)}
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View>
          <Image style={styles.backgroundImage} src={GamlsCert} />
          <Text style={styles.fullName}>{fullName}</Text>
          <Text style={styles.memberId}>{memberId}</Text>
          <Text style={styles.certId}>{certId}</Text>
        </View>
      </Page>
    </Document>
  </>
);

// Render the PDF document and provide a download link
const PDFCreator = (props) => (
  <div>
    <PDFDownloadLink
      document={<CertFormat {...props} />}
      fileName={props.memberId + "_cert.pdf"}
      style={{ textDecoration: "none" }}
    >
      {({ loading }) => (
        <Button
          variant="contained"
          color="primary"
          startIcon={<CloudDownload />}
          disabled={loading}
        >
          {loading
            ? "Loading certificate..."
            : "Download Membership Certificate"}
        </Button>
      )}
    </PDFDownloadLink>
  </div>
);

export default PDFCreator;
