import { Box, Typography } from "@mui/material";

const IconedText = ({ text, icon, noCenter }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: noCenter || "center" }}>
      {icon}
      <Typography
        variant={"body2"}
        gutterBottom
        sx={{ pl: 1 }}
        textTransform={"capitalize"}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default IconedText;
