import { Banner } from "../../components/index";
import { CssBaseline } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Content } from "./branchesElements";
import UnderConstruction from "../../components/underConstruction";
import {
  Page,
  View,
  Text,
  PDFViewer,
  StyleSheet,
  Canvas,
  Image,
} from "@react-pdf/renderer";
import { Document } from "react-pdf";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const Branches = () => {
  const bannerProps = {
    title: "Branches",
    subTitle: "Greater Accra Region",
  };
  return (
    <>
      <CssBaseline />
      {/* <Banner {...bannerProps} />
      <Content /> */}

      <UnderConstruction />
    </>
  );
};

export default Branches;
