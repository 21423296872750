import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { bannerConverter } from "../models/bannerModel";
import { saveData } from "./bannerSlice";

const readBanners = () => {
  let list = [];
  return async (dispatch) => {
    const querySnapshot = await getDocs(
      collection(db, "cms/website/banners").withConverter(bannerConverter)
    );
    querySnapshot.forEach((doc) => {
      list.push({ ...doc.data(), id: doc.id });
    });
    dispatch(saveData(list));
  };
};

const updateBanners = () => {
  return (dispatch) => {
    onSnapshot(
      collection(db, "cms/website/banners").withConverter(bannerConverter),
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {});
      }
    );
  };
};

const updateBanner = () => {
  return (dispatch) => {
    onSnapshot(
      collection(db, "cms/website/banners").withConverter(bannerConverter),
      (querySnapshot) => {
        const resources = [];
        querySnapshot.forEach((doc) => {
          resources.push({ ...doc.data(), id: doc.id });
        });
        dispatch(saveData(resources));
      }
    );
  };
};

export { readBanners, updateBanner, updateBanners };
