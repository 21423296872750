import { Box, Stack, TextField, Typography } from "@mui/material";
import React, { Component } from "react";
import { Title } from "../../components";
import { fieldStyle, PrimaryButton } from "./profileElements";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function uploadImageCallBack(file) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.imgur.com/3/image");
    xhr.setRequestHeader("Authorization", "Client-ID XXXXX");
    const data = new FormData();
    data.append("image", file);
    xhr.send(data);
    xhr.addEventListener("load", () => {
      const response = JSON.parse(xhr.responseText);
      resolve(response);
    });
    xhr.addEventListener("error", () => {
      const error = JSON.parse(xhr.responseText);
      reject(error);
    });
  });
}

class EditorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  render() {
    const { editorState } = this.state;
    return (
      <div className="editor">
        <Editor
          editorState={editorState}
          onEditorStateChange={this.onEditorStateChange}
          placeholder={"Type your message here…"}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            image: {
              uploadCallback: uploadImageCallBack,
              alt: { present: true, mandatory: true },
            },
          }}
        />
      </div>
    );
  }
}

const Help = () => {
  return (
    <Box
      sx={{
        px: { xs: "15px", md: "140px" },
        pt: { xs: 8, md: "82px" },
        mb: { xs: 8, mb: 12 },
        width: { xs: "100%", md: "70%" },
      }}
    >
      <Stack spacing={3}>
        <Box>
          <Title
            title={"Help"}
            subTitle={
              "Send us your complaints, we will reply as soon as we can."
            }
          />
        </Box>

        <Typography variant={"h5"} fontWeight={"bold"}>
          {"How can we help you"}
        </Typography>

        <TextField
          sx={fieldStyle}
          variant={"outlined"}
          label={"Message Title"}
          placeholder={"Message Title"}
          type={"text"}
        />

        <Box
          sx={{ borderRadius: "20px", border: "1px solid #cecece", padding: 2 }}
        >
          <EditorContainer />
        </Box>
        <PrimaryButton text={"Send Message"} />
      </Stack>
    </Box>
  );
};
export default Help;
