import * as React from "react";
import { GamlsLogo, HamburgerIcon, ExpandLess, ExpandMore } from "../assets";
import {
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  Collapse,
  AppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  Button,
  MenuItem,
  Stack,
} from "@mui/material";
import {
  NavLink,
  useNavigate,
  matchPath,
  useLocation,
  useParams,
} from "react-router-dom";
import { about, pages, routePaths } from "../constants";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../data/firebase";
import ProfileNav from "./profileNav";
import { useSelector } from "react-redux";

const buttonStyle = {
  my: 2,
  color: "black",
  textTransform: "initial",
  fontSize: { md: 12, sm: 8, lg: 14, xl: 16 },
  textAlign: "center",
};

function getStatus(data, id) {
  const { name } = data.find((x) => x.id === id);

  return {
    name,
    percentage: `0% Completed`,
  };
}

const NavBar = () => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [height, setHeight] = React.useState(0);
  const [slide, setSlide] = React.useState(false);
  const { pathname } = useLocation();
  const { id } = useParams();
  const { data } = useSelector((state) => state.categories);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const isLessonDetails = React.useMemo(
    () => Boolean(matchPath("/learning/:id", pathname)),
    [pathname]
  );

  const status = isLessonDetails ? getStatus(data, id) : {};

  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  React.useEffect(() => {
    const isLesson = Boolean(isLessonDetails);
    setHeight({
      xs: isLesson ? 134 : 70,
      md: isLessonDetails ? 190 : 100,
    });
  }, [isLessonDetails]);

  const handleHomeClick = React.useCallback(() => {
    navigate(routePaths.home);
  }, [navigate]);

  const handleClick = React.useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (o) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSlide(o);
  };

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{ bgcolor: "white" }}
        component={"nav"}
        elevation={isLessonDetails ? 0 : 2}
      >
        <Toolbar
          disableGutters
          sx={{
            padding: { md: "12px 70px", xs: "10px" },
            alignItems: "center",
          }}
        >
          <IconButton
            disableRipple={true}
            disableFocusRipple
            onClick={handleHomeClick}
          >
            <Box
              component={"img"}
              src={GamlsLogo}
              width={{ xs: 120, sm: 180, md: 180, lg: 200, xl: 220 }}
              height={{ xs: 40, sm: 50, md: 60 }}
              loading={"lazy"}
            />
          </IconButton>

          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "flex",
                md: "none",
                justifyContent: "flex-end",
                overflow: "hidden",
              },
            }}
          >
            <IconButton size="large" onClick={() => setSlide((prev) => !prev)}>
              <img
                src={HamburgerIcon}
                width={35}
                height={35}
                alt={"navigation menu"}
              />
            </IconButton>

            <SwipeableDrawer
              anchor={"left"}
              BackdropProps={{ invisible: true }}
              open={slide}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              disableBackdropTransition={!iOS}
              disableDiscovery={iOS}
              sx={{
                display: { xs: "inherit", md: "none" },
                "& .MuiDrawer-paper": {
                  width: "100%",
                  height: "fit-content",
                  position: "absolute",
                  top: 75,
                },
                overflow: "auto",
              }}
            >
              <React.Fragment>
                <ListItemButton onClick={handleClick}>
                  <ListItemText primary={"About"} />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {about.map((p) => {
                      return (
                        <ListItemButton
                          key={p.name}
                          sx={{ pl: 4 }}
                          LinkComponent={NavLink}
                          to={p.path}
                        >
                          <ListItemText primary={p.name} />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              </React.Fragment>
              <List>
                {pages.map((page) => (
                  <ListItemButton
                    key={page.name}
                    LinkComponent={NavLink}
                    to={page.path}
                  >
                    <ListItemText primary={page.name} />
                  </ListItemButton>
                ))}
                {user ? (
                  <ProfileNav
                    onClick={() => {
                      setSlide(false);
                    }}
                  />
                ) : (
                  <ListItemButton
                    LinkComponent={NavLink}
                    to={routePaths.login}
                    sx={{ fontWeight: "bold" }}
                  >
                    {"Login"}
                  </ListItemButton>
                )}
              </List>
            </SwipeableDrawer>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
            }}
          >
            <Button
              aria-haspopup={"true"}
              aria-controls={"menu-appbar"}
              aria-label={"about"}
              onClick={(e) => setAnchorElUser(e.currentTarget)}
              sx={{
                ...buttonStyle,
                mr: 2,
              }}
              endIcon={<ExpandMore />}
            >
              {"About"}
            </Button>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {about.map((setting, i) => (
                <MenuItem
                  key={setting.name}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(about[i].path);
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>

            {pages.map((page, index) => (
              <React.Fragment key={index}>
                <Button
                  LinkComponent={NavLink}
                  to={page.path}
                  sx={{
                    ...buttonStyle,
                    mr: 2,
                    "&.active": {
                      color: "primary.main",
                    },
                  }}
                >
                  {page.name}
                </Button>
              </React.Fragment>
            ))}
          </Box>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ bgcolor: "primary.main", width: 3 }}
            />
            {user ? (
              <ProfileNav />
            ) : (
              <Button
                LinkComponent={NavLink}
                to={routePaths.login}
                sx={{ fontWeight: "bold", m: 2, ...buttonStyle }}
              >
                {"Login"}
              </Button>
            )}
          </Box>
        </Toolbar>
        <Collapse in={Boolean(isLessonDetails)}>
          <Toolbar
            component={Stack}
            direction={"row"}
            justifyContent={"space-between"}
            disableGutters
            sx={{
              px: { md: "80px", xs: "10px" },
              alignItems: "center",
              color: "black",
              display: isLessonDetails ? "flex" : "none",
            }}
          >
            <Typography
              fontWeight={"bold"}
              sx={{
                fontSize: { xs: "14px", md: "24px" },
              }}
            >
              {status.name}
            </Typography>
            <Typography>{status.percentage}</Typography>
          </Toolbar>
        </Collapse>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          minHeight: height,
        }}
      ></Box>
    </Box>
  );
};

export default NavBar;
