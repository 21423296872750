export class NameModel {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.id = obj.id != null ? obj.id : "";
    this.name = obj.name != null ? obj.name : "";
    this.other = obj.other != null ? obj.other : "";
    this.other1 = obj.other1 != null ? obj.other1 : "";
    this.other2 = obj.other2 != null ? obj.other2 : "";
    this.date = obj.date != null ? obj.date : "";
  }
}

export const nameConverter = {
  toFirestore: (data) => {
    return {
      id: data.id,
      name: data.banner_text,
      other: data.other,
      other1: data.other1,
      other2: data.other2,
      date: data.date,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new NameModel({
      id: data.id,
      name: data.name,
      other: data.other,
      other1: data.other1,
      other2: data.other2,
      date: data?.date?.seconds
        ? new Date(data?.date?.seconds * 1000).toLocaleDateString()
        : data.date,
    });
  },
};
