import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { deleteInfo } from "../data/firebase";

function headerText(text, id) {
  return (
    <TableCell key={id} align="left">
      <Typography variant="body5" fontWeight="bold">
        {text}
      </Typography>
    </TableCell>
  );
}
export default function EduTable({ headerData, bodyData }) {
  return (
    <TableContainer sx={{ m: { md: "20px 0px" } }} component={Paper}>
      <Table sx={{ minWidth: 65 }} aria-label="edu table">
        <TableHead>
          <TableRow>
            {headerData.map((header, id) => headerText(header, id))}
            {headerText("Delete")}
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyData.map((row, id) => (
            <TableRow key={id}>
              <TableCell align="left">{row.school}</TableCell>
              <TableCell align="left" sx={{ width: "200px" }}>
                {row.from}
              </TableCell>
              <TableCell align="left" sx={{ width: "200px" }}>
                {row.to}
              </TableCell>
              <TableCell align="left">{row.qualification}</TableCell>
              <TableCell align="left">{row.yearObtained}</TableCell>
              <TableCell align="left">
                <IconButton onClick={() => deleteInfo(row.id, "eduHistory")}>
                  <Delete color={"primary"} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
