import { SvgIcon } from "@mui/material";
import { ReactComponent as LocationOnIcon } from '../icons/ic_location.svg';

function LocationOn(props){
    return  <SvgIcon
    {...props}
    component={LocationOnIcon}
    inheritViewBox
  />
}

export default LocationOn