import { SvgIcon } from "@mui/material";
import { ReactComponent as ArrowBackIosIcon } from '../icons/ic_arrow_back.svg';

function ArrowBackIos(props){
    return  <SvgIcon
    {...props}
    component={ArrowBackIosIcon}
    inheritViewBox
  />
}

export default ArrowBackIos