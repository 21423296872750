import { SvgIcon } from "@mui/material";
import { ReactComponent as BusinessOutlined } from '../icons/ic_company.svg';

function BusinessOutlinedIcon(props){
    return  <SvgIcon
    {...props}
    component={BusinessOutlined}
    inheritViewBox
  />
}

export default BusinessOutlinedIcon