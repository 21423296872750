import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { Markup } from "interweave";
import WebShareButton from "./webShareButton";

const NewsCard = (props) => {
  const {
    date,
    title,
    content,
    img_url,
    height,
    width,
    id,
    type,
    loading,
    onNewsClick = () => {},
  } = props;

  return (
    <Paper
      className={"parento"}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onNewsClick();
      }}
      sx={{
        borderRadius: "20px",
        height: height,
        minWidth: width,
      }}
    >
      {loading ? (
        <Skeleton variant={"rectangular"} width={"100%"}>
          <Box sx={{ paddingTop: "60%" }}></Box>
        </Skeleton>
      ) : (
        <Box
          className={"childo"}
          sx={{ backgroundImage: `url(${img_url})` }}
        ></Box>
      )}
      <Box p={3} sx={{ display: "flex", alignItems: "flex-end" }}>
        <Box>
          <Stack flex={1} direction={"row"} pb={{ xs: 2, md: 0 }}>
            {loading ? (
              <Skeleton>
                <Typography sx={{ mr: 2 }}>.</Typography>
              </Skeleton>
            ) : (
              <Typography sx={{ mr: 2 }}>{date}</Typography>
            )}
            {loading ? (
              <Skeleton width={"100%"}>
                <Typography>.</Typography>
              </Skeleton>
            ) : (
              <Typography fontWeight={"bold"} textTransform={"capitalize"}>
                {type}
              </Typography>
            )}
          </Stack>
          {loading ? (
            <Skeleton width={"100%"}>
              <Typography variant={"h5"}>.</Typography>
            </Skeleton>
          ) : (
            <Typography
              variant={"h5"}
              gutterBottom
              fontWeight={"bold"}
              sx={{ display: { xs: "none", md: "inherit" } }}
            >
              {title}
            </Typography>
          )}
          <Box sx={{ maxHeight: 100, overflow: "hidden" }}>
            {loading ? (
              <Skeleton width={"100%"}>
                <Markup content={content} />
              </Skeleton>
            ) : (
              <Markup content={content} />
            )}
          </Box>
        </Box>

        {loading ? (
          <Skeleton variant={"circular"}>
            <WebShareButton />
          </Skeleton>
        ) : (
          <WebShareButton
            title={"Share news via"}
            url={`https://${window.location.hostname}/events/${id}`}
            text={"It's seasoned hot news on GAMLS via "}
          />
        )}
      </Box>
    </Paper>
  );
};

export default NewsCard;
