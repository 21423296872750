import { Box, Divider, Stack, Typography } from "@mui/material";
import { BannerBg } from "../assets";

const bannerStyle = {
  backgroundImage: `url(${BannerBg})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
};

export const Banner = ({ title, subTitle, otherTitle, color }) => {
  return (
    <Box
      sx={{
        ...(color
          ? {
              background: color,
            }
          : { ...bannerStyle }),
        p: { xs: "32px 15px", md: "40px 80px" },
        color: "white",
      }}
    >
      <Stack spacing={{ xs: 2, md: 3 }}>
        <Stack
          spacing={2}
          divider={
            otherTitle && (
              <Divider sx={{ bgcolor: "white", width: 3 }} flexItem />
            )
          }
          direction={otherTitle ? "row" : "column"}
        >
          <Typography fontWeight={"bold"} sx={{ fontSize: { xs: 18, md: 32 } }}>
            {title}
          </Typography>
          <Typography sx={{ fontSize: { xs: 18, md: 32 } }}>
            {otherTitle}
          </Typography>
        </Stack>
        <Typography variant={"body1"}>{subTitle}</Typography>
      </Stack>
    </Box>
  );
};

export default Banner;
