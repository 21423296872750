import { Box, Card, CssBaseline, Stack } from "@mui/material";
import { Banner, Title } from "../../components";
import { AltBanner } from "../../components";
import { Content } from "./resourceElements";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { updateResources } from "../../data";
import { LoginContent } from "../login/loginContent";
import { MemberToolsAccess } from "./memberToolsAccess";

const Resources = () => {
  const dispatch = useDispatch();
  const bannerProps = {
    title: "Resources",
    subTitle:
      "GAMLS is advancing the next generation of medical laboratory delivery through policies that expand access to lifesaving testing services.",
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(updateResources());
    }, 2000);

    return () => clearTimeout(getData);
  }, [dispatch]);
  return (
    <>
      <CssBaseline />
      <Banner {...bannerProps} />
      <Content />
    </>
  );
};
export default Resources;
