import { Button, Divider, Stack, Typography } from "@mui/material";
import Lottie from "lottie-react";
import trophy from "../../assets/json/trophy.json";

const LearningComplete = ({ hook }) => {
  const { handlePrevious, handleDone } = hook;

  return (
    <Stack
      flex={1}
      alignItems={"center"}
      py={{ xs: 6, md: 6 }}
      px={{ xs: "15px", md: "140px" }}
      spacing={2}
      sx={{ textAlign: "center" }}
    >
      <Lottie animationData={trophy} />
      <Typography fontWeight={"bold"}>Course Completed Successfully</Typography>
      <Typography fontSize={{ xs: "32px", md: "40px" }} fontWeight={"bold"}>
        Your Score 80%
      </Typography>
      <Typography color={"text.secondary"}>
        Your Certificate will be sent to your through your email.
      </Typography>
      <Divider sx={{ bgcolor: "black", width: "100%" }} />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        flex={1}
        sx={{ width: "100%" }}
      >
        <Button onClick={handlePrevious}>Prev Lesson</Button>
        <Button onClick={handleDone}>Done</Button>
      </Stack>
    </Stack>
  );
};

export default LearningComplete;
