import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import { persistStore, persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import bannerSlice from "./banners/bannerSlice";
import electionSlice from "./elections/electionSlice";
import eventSlice from "./events/eventSlice";
import jobSlice from "./jobs/jobSlice";
import newsSlice from "./news/newsSlice";
import notificationSlice from "./notifications/notificationSlice";
import pressSlice from "./press/pressSlice";
import resourceSlice from "./resources/resourceSlice";
import learningSlice from "./learning/learningSlice";
import categoriesSlice from "./learning/categoriesSlice";
import coursesSlice from "./learning/coursesSlice";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["lessons", "courses"],
};

const userPersistConfig = {
  key: "user",
  storage: storageSession,
  whitelist: ["user"],
};

const appReducer = combineReducers({
  auth: persistReducer(userPersistConfig, authReducer),
  resource: resourceSlice.reducer,
  job: jobSlice.reducer,
  banner: bannerSlice.reducer,
  news: newsSlice.reducer,
  events: eventSlice.reducer,
  press: pressSlice.reducer,
  notifications: notificationSlice.reducer,
  elections: electionSlice.reducer,
  lessons: learningSlice.reducer,
  categories: categoriesSlice.reducer,
  courses: coursesSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/clear") {
    storage.removeItem("persist:root");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
