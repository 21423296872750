import { SvgIcon } from "@mui/material";
import { ReactComponent as BookIcon } from '../icons/ic_book.svg';

function Book(props){
    return  <SvgIcon
    {...props}
    component={BookIcon}
    inheritViewBox
  />
}

export default Book