import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const NameCard = ({ name, present }) => {
  return (
    <Box
      sx={{
        borderRadius: "20px",
        bgcolor: "white",
        p: { xs: 0, md: "26px 24px" },
        maxWidth: "250px",
        minHeight: "100px",
        maxHeight: "fit-content",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        color={present ? "primary.main" : "text.secondary"}
        textAlign={"center"}
        fontSize={{ xs: 15, md: 22 }}
      >
        {name}
      </Typography>
    </Box>
  );
};

const Group = ({ term, present, name }) => {
  return (
    <Stack flex={1}>
      <Stack
        sx={{
          display: { xs: "none", md: "flex", flexDirection: "column" },
          zIndex: 99,
        }}
        alignItems={"center"}
        justifyContent={"space-around"}
        spacing={2}
      >
        <Typography
          variant={"h6"}
          color={present ? "primary.main" : "text.secondary"}
          textTransform={"uppercase"}
          fontWeight={"bold"}
        >
          {term}
        </Typography>
        <Box
          sx={{
            width: "32px",
            height: "32px",
            borderRadius: "50%",
            bgcolor: present ? "#F30813" : "#707070",
          }}
        ></Box>
        <NameCard present={present} name={name} />
      </Stack>

      <Stack
        sx={{ display: { xs: "inherit", md: "none", zIndex: 99 } }}
        alignItems={"center"}
        justifyContent="space-evenly"
        spacing={2}
        direction={{ xs: "row", md: "column" }}
      >
        <Box flex={0.25}>
          <Typography
            color={present ? "primary.main" : "text.secondary"}
            textTransform={"uppercase"}
            fontWeight={"bold"}
            fontSize={{ xs: 16 }}
          >
            {term}
          </Typography>
        </Box>
        <Box
          flex={0.5}
          sx={{
            minWidth: "32px",
            maxWidth: "32px",
            height: "32px",
            borderRadius: "50%",
            bgcolor: present ? "#F30813" : "#707070",
          }}
        ></Box>
        <Box flex={{ xs: 0.5 }}>
          <NameCard present={present} name={name} />
        </Box>
      </Stack>
    </Stack>
  );
};

const Stepper = ({ data }) => {
  return (
    <Stack spacing={6} sx={{ overflowX: "scroll" }}>
      {data.map((item, idx) => {
        return (
          <Stack
            sx={{ position: "relative" }}
            direction={{ xs: "row" }}
            key={idx}
          >
            <Box
              sx={{
                width: { xs: "3px", md: "100%" },
                height: { xs: "100%", md: "3px" },
                bgcolor: "black",
                position: "absolute",
                top: { xs: 0, md: 62 },
                bottom: 0,
                left: { xs: "38%", md: 0 },
                right: 0,
              }}
            ></Box>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              justifyContent={"space-around"}
              flex={1}
            >
              {item.group.map((i, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Group
                      term={`${i.start} - ${i.end}`}
                      present={i.end.toLowerCase().includes("present")}
                      name={i.name}
                    />
                  </React.Fragment>
                );
              })}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default Stepper;
