import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  IconButton,
  Stack,
  CardActionArea,
  Skeleton,
  Box,
} from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { ShareOutlined } from "../assets";

const EventCard = (props) => {
  const { title, date, location, img_url, id, medium, loading } = props;
  const navigate = useNavigate();

  const url = `/events/${id}`;

  const handleClick = useCallback(() => {
    navigate(url);
  }, [navigate, url]);

  return (
    <Card sx={{ maxWidth: 378, borderRadius: 5, minWidth: 300 }}>
      <CardActionArea onClick={handleClick}>
        {loading ? (
          <Skeleton variant={"rectangular"} width={"100%"}>
            <Box sx={{ paddingTop: "200px" }}></Box>
          </Skeleton>
        ) : (
          <CardMedia
            component="img"
            alt="event image"
            height="200px"
            src={img_url}
            loading={"lazy"}
          />
        )}

        <CardContent>
          <Stack spacing={2}>
            {loading ? (
              <Skeleton variant={"text"} width={"100%"}>
                <Typography variant="h6" component="div">
                  .
                </Typography>
              </Skeleton>
            ) : (
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                color="text.secondary"
                fontWeight={"bold"}
              >
                {title}
              </Typography>
            )}
            {loading ? (
              <Skeleton variant={"text"} width={"20%"}></Skeleton>
            ) : (
              <Typography variant="body1" color="primary.main">
                {date}
              </Typography>
            )}
            {loading ? (
              <Skeleton variant={"text"} width={"40%"}></Skeleton>
            ) : (
              <Typography variant="body1" color="text.secondary">
                {location}
              </Typography>
            )}
          </Stack>
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{ display: "flex", flexGrow: 1, justifyContent: "space-between" }}
      >
        {loading ? (
          <Skeleton variant={"text"} width={"30%"} />
        ) : (
          <Typography
            variant={"body1"}
            fontWeight={"bold"}
            textTransform={"capitalize"}
          >
            {medium}
          </Typography>
        )}

        {loading ? (
          <Skeleton variant={"circular"}>
            <RWebShare
              data={{
                text: "Hi, I would like to invite you for an upcoming event on GAMLS: ",
                url: `https://${window.location.hostname}/events/${id}`,
                title: "Share event via",
              }}
            >
              <IconButton color={"primary"}>
                <ShareOutlined />
              </IconButton>
            </RWebShare>
          </Skeleton>
        ) : (
          <RWebShare
            data={{
              text: "Hi, I would like to invite you for an upcoming event on GAMLS: ",
              url: `https://${window.location.hostname}/events/${id}`,
              title: "Share event via",
            }}
          >
            <IconButton color={"primary"}>
              <ShareOutlined />
            </IconButton>
          </RWebShare>
        )}
      </CardActions>
    </Card>
  );
};

export default EventCard;
