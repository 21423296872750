import {
  Stack,
  Tab,
  Box,
  Divider,
  Typography,
  Grid,
  TextField,
  Chip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  LetterAvatar,
  ProfileTable,
  EduTable,
  PDFCreator,
  PasswordField,
  CustomAccordion,
} from "../../components";
import {
  educationSteps,
  employmentSteps,
  registrationSteps,
  securitySteps,
  specialtyFormValues,
  specialtySteps,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useFormik } from "formik";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db, getUser, auth, changePassword } from "../../data/firebase";
import {
  changePasswordValidationSchema,
  profileUpdateValidationSchema,
  userValidationSchema,
} from "../../utils/validationSchema";
import { getUserLearning } from "../../data/auth/authAction";
import { useAuthState } from "react-firebase-hooks/auth";
import UnderConstruction from "../../components/underConstruction";
import Swal from "sweetalert2";
import { mapAuthCodeToMessage } from "../../utils/mapCodeToMessage";
import { LoadingButton } from "@mui/lab";
import { getAuth, updatePassword } from "firebase/auth";
import {
  eduHistoryHeader,
  empHistoryHeader,
  generateRandomNumber,
} from "../../constants/appConstants";
import { VisibilityOff } from "../../assets";
import { getJobs, getNotifications } from "../../data";
import {
  CheckCircleOutline,
  CloudDownload,
  InfoOutlined,
} from "@mui/icons-material";

export const fieldStyle = {
  "& input": {
    paddingLeft: "20px",
    bgcolor: "white",
    borderRadius: "20px",
  },
  "& fieldset": {
    borderRadius: "20px",
    // borderColor: "blue",
  },
  "& .MuiInputBase-input": {
    bgcolor: "white",
    borderRadius: "20px",
  },
};

const options = [
  { name: "Most recent", value: "recent" },
  { name: "Popular", value: "popular" },
  { name: "Recommended", value: "rec" },
];

// export const PrimaryButton = ({ text = "Submit", onClick = () => {} }) => (
//   <Button
//     variant={"contained"}
//     disableElevation
//     sx={{
//       width: { xs: "100%", md: "32%" },
//       height: "48px",
//       bgcolor: "black",
//       textTransform: "capitalize",
//       fontSize: 16,
//       borderRadius: 5,
//       my: 6,
//       fontWeight: "bold",
//       whiteSpace: "nowrap",
//     }}
//     onClick={onClick}
//   >
//     {text}
//   </Button>
// );

export const PrimaryButton = ({ text = "Submit", ...props }) => (
  <LoadingButton
    variant={"contained"}
    disableElevation
    sx={{
      width: { xs: "100%", md: "32%" },
      height: "48px",
      bgcolor: "black",
      textTransform: "capitalize",
      fontSize: 16,
      borderRadius: 5,
      my: 6,
      fontWeight: "bold",
      whiteSpace: "nowrap",
    }}
    type={"submit"}
    {...props}
  >
    {text}
  </LoadingButton>
);

const ProfilePanel = ({ user, navigate, dispatch }) => {
  const [resetEmail, setResetEmail] = useState("");

  const fullName =
    user.firstName && user.lastName
      ? `${user?.firstName} ${user?.lastName} ${user?.otherName} `
      : "Unidentified User";

  const formik = useFormik({
    initialValues: user,
    validationSchema: profileUpdateValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      console.log(values);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      try {
        const userRef = doc(db, "users", auth.currentUser.uid);
        await setDoc(userRef, {
          ...values,
          date_modified: serverTimestamp(),
          merge: true,
        })
          .then(() => {
            getUser(dispatch);
            formik.setSubmitting(false);
            Swal.fire({
              icon: "success",
              title: "Update Successful",
              text: "Success",
              confirmButtonColor: "#000000",
            });
          })
          .catch((error) => {
            throw new Error(error.message);
          });
      } catch (err) {
        formik.setSubmitting(false);
        Swal.fire({
          icon: "error",
          title: "Update unsuccessful",
          text: mapAuthCodeToMessage(err.code),
          confirmButtonColor: "#000000",
        });
      }
    },
  });
  const empFormik = useFormik({
    initialValues: {
      from: "1990-12-01",
      to: "1996-12-01",
      region: user.region,
      companyType: "Government",
    },
    validationSchema: userValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      // console.log(values);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      try {
        const userRef = doc(db, "users", auth.currentUser.uid);
        //attach a unique ID to each record
        const items = {
          ...values,
          id: generateRandomNumber(),
        };
        await setDoc(
          userRef,
          {
            employmentHistory: user.employmentHistory
              ? user.employmentHistory.concat(items)
              : [items],
            date_modified: serverTimestamp(),
          },
          { merge: true }
        )
          .then(() => {
            getUser(dispatch);
            formik.setSubmitting(false);
            Swal.fire({
              icon: "success",
              title: "Update Successful",
              text: "Success",
              confirmButtonColor: "#000000",
            });
          })
          .catch((error) => {
            throw new Error(error.message);
          });
      } catch (err) {
        formik.setSubmitting(false);
        Swal.fire({
          icon: "error",
          title: "Update unsuccessful",
          text: mapAuthCodeToMessage(err.code),
          confirmButtonColor: "#000000",
        });
      }
    },
  });
  const eduFormik = useFormik({
    initialValues: {
      from: "1990-12-01",
      to: "1996-12-01",
      qualification: "Basic",
      yearObtained: "1996-12-01",
    },
    validationSchema: userValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      console.log(values);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      try {
        const userRef = doc(db, "users", auth.currentUser.uid);
        //attach a unique ID to each record
        const items = {
          ...values,
          id: generateRandomNumber(),
        };
        await setDoc(
          userRef,
          {
            eduHistory: user.eduHistory
              ? user.eduHistory.concat(items)
              : [items],
            date_modified: serverTimestamp(),
          },
          { merge: true }
        )
          .then(() => {
            getUser(dispatch);
            formik.setSubmitting(false);
            Swal.fire({
              icon: "success",
              title: "Update Successful",
              text: "Success",
              confirmButtonColor: "#000000",
            });
          })
          .catch((error) => {
            throw new Error(error.message);
          });
      } catch (err) {
        formik.setSubmitting(false);
        Swal.fire({
          icon: "error",
          title: "Update unsuccessful",
          text: mapAuthCodeToMessage(err.code),
          confirmButtonColor: "#000000",
        });
      }
    },
  });
  const specialtyFormik = useFormik({
    enableReinitialize: true,
    initialValues: user.workPracticeSpecialty ?? specialtyFormValues,

    onSubmit: async (values) => {
      // Handle form submission
      console.log("Form submitted with values:", values);

      console.log(values);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      try {
        const userRef = doc(db, "users", auth.currentUser.uid);
        await setDoc(
          userRef,
          {
            workPracticeSpecialty: values,
            date_modified: serverTimestamp(),
          },
          { merge: true }
        )
          .then(() => {
            getUser(dispatch);
            formik.setSubmitting(false);
            Swal.fire({
              icon: "success",
              title: "Update Successful",
              text: "Success",
              confirmButtonColor: "#000000",
            });
          })
          .catch((error) => {
            throw new Error(error.message);
          });
      } catch (err) {
        formik.setSubmitting(false);
        Swal.fire({
          icon: "error",
          title: "Update unsuccessful",
          text: mapAuthCodeToMessage(err.code),
          confirmButtonColor: "#000000",
        });
      }
    },
  });

  const errorMsg = (placeholder) => {
    return (
      <Stack direction={"row"} sx={{ display: "flex", mt: 1 }} spacing={1}>
        <InfoOutlined sx={{ fontSize: 16, color: "red" }} />
        <Typography variant="caption" color={"red"}>
          Kindly add {placeholder}
        </Typography>
      </Stack>
    );
  };
  return (
    <Box>
      <Stack
        sx={{ overflow: "hidden", px: { xs: 0, md: 8 } }}
        spacing={{ xs: 2, md: 4 }}
      >
        <Stack direction={"row"} spacing={{ xs: 2, md: 4 }}>
          <LetterAvatar name={fullName} />
          <Divider orientation="vertical" variant="middle" flexItem />
          <Stack spacing={{ xs: 1, md: 2 }} justifyContent={"center"}>
            <Typography variant={"h5"} fontWeight={"bold"}>
              {fullName}
            </Typography>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              alignItems={{ xs: "flex-start", md: "center" }}
            >
              {user.memberId ? <Typography>{user?.memberId}</Typography> : null}

              <Chip
                label={user.activated ? "Activated" : "Pending Activation"}
                color={user.activated ? "success" : "primary"}
              />
            </Stack>
            <Typography>{user?.email}</Typography>

            {user.activated && !user.restricted && (
              <PDFCreator
                fullName={fullName}
                memberId={user.memberId}
                certId={user.certId}
              />
            )}
          </Stack>
        </Stack>
        <Stack>
          <Typography variant={"h5"} fontWeight={"bold"} gutterBottom>
            Update Your Profile
          </Typography>
          <Typography gutterBottom color={"text.secondary"}>
            Add information about yourself
          </Typography>
        </Stack>
      </Stack>
      <Box
        px={{ xs: 0, md: 8 }}
        py={2}
        component={"form"}
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        {registrationSteps.map((item, index) => {
          return (
            registrationSteps.length - 1 === index || (
              <Box key={index}>
                <Typography variant={"body1"} fontWeight={"bold"} pb={2} pt={6}>
                  {item.title}
                </Typography>
                <Grid
                  container
                  rowSpacing={{ xs: 3, md: 4 }}
                  columnSpacing={{ md: 3, xs: 1 }}
                  columns={{ xs: 2, sm: 8, md: 12 }}
                >
                  {item.data.map((field, i) => {
                    return (
                      <Grid item xs={12} sm={4} md={4} key={i}>
                        {field.menu ? (
                          <TextField
                            sx={fieldStyle}
                            key={field.id}
                            value={formik.values[field.id]}
                            id={field.id}
                            select
                            fullWidth
                            defaultValue={user[field?.id]}
                            onChange={formik.handleChange}
                            label={field.placeholder}
                            error={
                              formik.touched[field.id] &&
                              Boolean(formik.errors[field.id])
                            }
                            SelectProps={{
                              native: true,
                            }}
                            variant={"outlined"}
                          >
                            {field.menu.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </TextField>
                        ) : (
                          <TextField
                            fullWidth
                            sx={{
                              ...fieldStyle,
                              "& fieldset": {
                                ...fieldStyle["& fieldset"],
                                borderColor: `${
                                  field.isRequired && !formik.values[field.id]
                                    ? "red"
                                    : null
                                }`,
                              },
                            }}
                            key={field.id}
                            value={formik.values[field.id]}
                            variant={"outlined"}
                            id={field.id}
                            placeholder={field.placeholder}
                            disabled={field.disabled}
                            label={field.placeholder}
                            type={field.type}
                            onChange={formik.handleChange}
                            helperText={formik.errors[field.id]}
                            error={
                              formik.touched[field.id] &&
                              Boolean(formik.errors[field.id])
                            }
                          />
                        )}
                        {field.isRequired &&
                          !formik.values[field.id] &&
                          errorMsg(field.placeholder)}
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            )
          );
        })}
        <PrimaryButton
          text={"Save Data"}
          disabled={formik.isSubmitting}
          loading={formik.isSubmitting}
        />
      </Box>
      <Stack
        sx={{
          borderColor: "divider",
          borderTop: 1,
          // alignItems: "center",
          mb: { xs: 6, md: 8 },
          mx: { xs: 5, md: 8 },
          display: "flex",
        }}
        component={"form"}
        noValidate
        autoComplete="off"
        onSubmit={specialtyFormik.handleSubmit}
      >
        {specialtySteps.map((item, index) => {
          return (
            <Box key={index}>
              <Stack
                direction={"row"}
                spacing={4}
                pb={1}
                pt={6}
                alignItems="flex-end"
              >
                <Typography variant={"body1"} fontWeight={"bold"}>
                  {item.title}
                </Typography>
                {!user.workPracticeSpecialty &&
                  errorMsg("your Work Specialty data")}
              </Stack>

              <Typography variant={"body1"} color={"gray"} pb={2}>
                {item.subtitle}
              </Typography>
              <Grid
                container
                // rowSpacing={{ xs: 5, md: 3 }}
                columnSpacing={{ md: 0, xs: 1 }}
                columns={{ xs: 2, sm: 8, md: 5 }}
              >
                {item.data.map((field, i) => {
                  return (
                    <Grid item xs={12} sm={4} md={1} key={i}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={field.id}
                            id={field.id}
                            name={field.name}
                            checked={specialtyFormik.values[field.name]}
                            onChange={() => {
                              specialtyFormik.setFieldValue(
                                field.name,
                                !specialtyFormik.values[field.name]
                              );
                            }}
                            error={
                              formik.touched[field.id] &&
                              Boolean(formik.errors[field.id])
                            }
                          />
                        }
                        label={
                          <span style={{ color: "gray" }}>
                            {field.placeholder}
                          </span>
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          );
        })}
        <PrimaryButton
          text={"Save Data"}
          disabled={specialtyFormik.isSubmitting}
          loading={specialtyFormik.isSubmitting}
        />
      </Stack>
      {/* Employment History */}
      <Box
        sx={{
          borderColor: "divider",
          borderTop: 1,

          mb: { xs: 6, md: 8 },
          mx: { xs: 5, md: 8 },
        }}
        py={2}
        component={"form"}
        noValidate
        autoComplete="off"
        onSubmit={empFormik.handleSubmit}
      >
        {employmentSteps.map((item, index) => {
          return (
            <Box key={index}>
              <Stack
                direction={"row"}
                spacing={4}
                pb={1}
                pt={6}
                alignItems="flex-end"
              >
                <Typography variant={"body1"} fontWeight={"bold"}>
                  {item.title}
                </Typography>

                {!user.employmentHistory ||
                  (user.employmentHistory.length === 0 &&
                    errorMsg("your Employment History"))}
              </Stack>
              <Typography variant={"body1"} color={"gray"} pb={2}>
                {item.subtitle}
              </Typography>
              <Grid
                container
                rowSpacing={{ xs: 3, md: 4 }}
                columnSpacing={{ md: 3, xs: 1 }}
                columns={{ xs: 2, sm: 8, md: 6 }}
              >
                {item.data.map((field, i) => {
                  return (
                    <Grid item xs={12} sm={5} md={1} key={i}>
                      {field.menu ? (
                        <TextField
                          sx={fieldStyle}
                          key={field.id}
                          value={empFormik.values[field.id]}
                          id={field.id}
                          select
                          fullWidth
                          defaultValue={user[field?.id]}
                          onChange={empFormik.handleChange}
                          label={field.placeholder}
                          error={
                            empFormik.touched[field.id] &&
                            Boolean(empFormik.errors[field.id])
                          }
                          SelectProps={{
                            native: true,
                          }}
                          variant={"outlined"}
                        >
                          {field.menu.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </TextField>
                      ) : (
                        <TextField
                          fullWidth
                          sx={fieldStyle}
                          key={field.id}
                          value={empFormik.values[field.id] ?? ""}
                          variant={"outlined"}
                          id={field.id}
                          placeholder={field.placeholder}
                          disabled={field.disabled}
                          label={field.placeholder}
                          type={field.type}
                          onChange={empFormik.handleChange}
                          helperText={empFormik.errors[field.id]}
                          error={
                            empFormik.touched[field.id] &&
                            Boolean(empFormik.errors[field.id])
                          }
                        />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          );
        })}

        <ProfileTable
          headerData={empHistoryHeader}
          bodyData={user.employmentHistory ?? []}
        />
        <PrimaryButton
          text={"Save Data"}
          disabled={empFormik.isSubmitting}
          loading={empFormik.isSubmitting}
        />
      </Box>
      {/* Educatioinal Background */}
      <Box
        sx={{
          borderColor: "divider",
          borderTop: 1,

          mb: { xs: 6, md: 8 },
          mx: { xs: 5, md: 8 },
        }}
        py={2}
        component={"form"}
        noValidate
        autoComplete="off"
        onSubmit={eduFormik.handleSubmit}
      >
        {educationSteps.map((item, index) => {
          return (
            <Box key={index}>
              <Stack
                direction={"row"}
                spacing={4}
                pb={1}
                pt={6}
                alignItems="flex-end"
              >
                <Typography variant={"body1"} fontWeight={"bold"}>
                  {item.title}
                </Typography>
                {!user.eduHistory ||
                  (user.eduHistory.length === 0 &&
                    errorMsg("your Education and Qualifications"))}
              </Stack>
              <Typography variant={"body1"} color={"gray"} pb={2}>
                {item.subtitle}
              </Typography>
              <Grid
                container
                rowSpacing={{ xs: 3, md: 4 }}
                columnSpacing={{ md: 3, xs: 1 }}
                columns={{ xs: 2, sm: 8, md: 6 }}
              >
                {item.data.map((field, i) => {
                  return (
                    <Grid item xs={12} sm={5} md={1} key={i}>
                      {field.menu ? (
                        <TextField
                          sx={fieldStyle}
                          key={field.id}
                          value={eduFormik.values[field.id]}
                          id={field.id}
                          select
                          fullWidth
                          defaultValue={user[field?.id]}
                          onChange={eduFormik.handleChange}
                          label={field.placeholder}
                          error={
                            empFormik.touched[field.id] &&
                            Boolean(eduFormik.errors[field.id])
                          }
                          SelectProps={{
                            native: true,
                          }}
                          variant={"outlined"}
                        >
                          {field.menu.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </TextField>
                      ) : (
                        <TextField
                          fullWidth
                          sx={fieldStyle}
                          key={field.id}
                          value={eduFormik.values[field.id] ?? ""}
                          variant={"outlined"}
                          id={field.id}
                          placeholder={field.placeholder}
                          disabled={field.disabled}
                          label={field.placeholder}
                          type={field.type}
                          onChange={eduFormik.handleChange}
                          helperText={eduFormik.errors[field.id]}
                          error={
                            eduFormik.touched[field.id] &&
                            Boolean(eduFormik.errors[field.id])
                          }
                        />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          );
        })}

        <EduTable
          headerData={eduHistoryHeader}
          bodyData={user.eduHistory ?? []}
        />
        <PrimaryButton
          text={"Save Data"}
          disabled={eduFormik.isSubmitting}
          loading={eduFormik.isSubmitting}
        />
      </Box>
    </Box>
  );
};
const SecurityPanel = ({ user, navigate, dispatch }) => {
  const [resetEmail, setResetEmail] = useState("");

  const fullName =
    user.firstName && user.lastName
      ? `${user?.firstName} ${user?.lastName}`
      : "Unidentified User";

  const formik = useFormik({
    initialValues: user,
    validationSchema: changePasswordValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      try {
        await changePassword(
          values.currentPassword,
          values.retypePassword,
          dispatch
        );
        formik.setSubmitting(false);
      } catch (err) {
        formik.setSubmitting(false);
        Swal.fire({
          icon: "error",
          title: "Update unsuccessful",
          text: mapAuthCodeToMessage(err.code),
          confirmButtonColor: "#000000",
        });
      }
    },
  });

  return (
    <Box>
      <Box px={{ xs: 0, md: 12 }} py={2}>
        <Stack component={"form"} onSubmit={formik.handleSubmit}>
          {securitySteps.map((item, index) => {
            return (
              <React.Fragment key={item.title}>
                <Typography variant={"body1"} fontWeight={"bold"} pb={2} pt={0}>
                  {item.title}
                </Typography>
                <Grid
                  container
                  rowSpacing={{ xs: 3, md: 4 }}
                  columnSpacing={{ md: 3, xs: 1 }}
                  columns={{ xs: 2, sm: 8, md: 12 }}
                >
                  {item.data.map((field, i) => {
                    return (
                      <Grid item xs={12} sm={4} md={4} key={i}>
                        <PasswordField
                          fullWidth
                          sx={fieldStyle}
                          key={field.id}
                          value={formik.values[field.id]}
                          variant={"outlined"}
                          id={field.id}
                          // type={field.type}
                          placeholder={field.placeholder}
                          disabled={field.disabled}
                          label={field.placeholder}
                          //  type={field.type}
                          onChange={formik.handleChange}
                          helperText={formik.errors[field.id]}
                          error={
                            formik.touched[field.id] &&
                            Boolean(formik.errors[field.id])
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </React.Fragment>
            );
          })}
          <PrimaryButton
            text={"Change Password"}
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
          />
        </Stack>
      </Box>
    </Box>
  );
};

const NotificationsPanel = () => {
  const dispatch = useDispatch();
  const { data: notifications, loading } = useSelector(
    (state) => state.notifications
  );
  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);
  return (
    <Stack alignItems={"center"} py={{ xs: 6, md: 0 }}>
      {loading ? (
        <Typography variant="h4" color="text.secondary" fontWeight="bold">
          Loading...
        </Typography>
      ) : !notifications || notifications.length === 0 ? (
        <Typography variant="h4" color="text.secondary" fontWeight="bold">
          No Notifications
        </Typography>
      ) : (
        <>
          {notifications.map((notification) => (
            <CustomAccordion
              key={notification.id}
              notification={notification}
            />
          ))}
        </>
      )}
    </Stack>
  );
};

const LearningPanel = ({ lessons, isFetching }) => {
  return (
    <Stack spacing={2} px={{ xs: 0, md: "80px" }}>
      {/* <Title title={"Courses You're Taking"} />

      <Stack direction={"row"} spacing={2}>
        <SelectInput label={"Order By"} options={options} />
        <Button>{"Clear"}</Button>
      </Stack> */}

      {/* <Grid
        container
        rowSpacing={{ xs: 2, md: 6 }}
        columnSpacing={{ xs: 2, md: 2 }}
      >
        {lessons?.map((lesson, index) => {
          return (
            <Grid item container key={index} xs={12} md={6}>
              <LearningCard
                lessonCount={lesson.lessonCount}
                status={lesson.status}
                title={lesson.title}
              />
            </Grid>
          );
        })}
      </Grid> */}
      <UnderConstruction />
    </Stack>
  );
};

export const Content = () => {
  const [value, setValue] = React.useState("1");
  const dispatch = useDispatch();
  const {
    userLearnings,
    user: localUser,
    learningsLoading,
  } = useSelector((state) => state.auth);
  const [user] = useAuthState(auth);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    switch (id) {
      case "learning":
        setValue("2");
        break;
      case "notifications":
        setValue("3");
        break;
      default:
        setValue("1");
        break;
    }
  }, [id]);
  useEffect(() => {
    dispatch(getUserLearning(user.uid));
  }, [dispatch, user.uid]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Stack flexGrow={1} spacing={2} py={6}>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            px: { xs: "15px", md: "80px" },
          }}
        >
          <TabList
            variant="scrollable"
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { background: "#F30813" } }}
            scrollButtons={"auto"}
            allowScrollButtonsMobile
            aria-label="lab API tabs example"
          >
            {[
              "My Personal Profile",
              // "My Learning",
              "Notifications",
              "Security",
            ].map((title, idx) => (
              <Tab label={title} key={title} value={`${idx + 1}`} />
            ))}
          </TabList>
        </Box>
        <TabPanel value={"1"}>
          <ProfilePanel
            user={localUser}
            navigate={navigate}
            dispatch={dispatch}
          />
        </TabPanel>
        {/* <TabPanel value={"2"}>
          <LearningPanel
            lessons={userLearnings}
            isFetching={learningsLoading}
          />
        </TabPanel> */}
        <TabPanel value={"2"}>
          <NotificationsPanel />
        </TabPanel>
        <TabPanel value={"3"}>
          <SecurityPanel
            user={localUser}
            navigate={navigate}
            dispatch={dispatch}
          />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};
