import {
  Box,
  Divider,
  Paper,
  Stack,
  Typography,
  Button,
  Grid,
  Grow,
} from "@mui/material";
import { Background } from "../../assets/index";
import { Newsletter, Title, NewsCard } from "../../components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCallback } from "react";

export const PressCard = ({ flex, height, data }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (id) => {
      navigate(`/news/${id}`, { state: { is_press: true } });
    },
    [navigate]
  );

  return (
    <Box flex={flex} sx={{ display: "flex" }} flexGrow={1}>
      <Paper
        sx={{
          flex: 1,
          display: "flex",
          borderRadius: 5,
          p: 3,
          flexDirection: "column",
          height: height ?? "450px",
          overflow: "auto",
        }}
      >
        <Title title={"Press Releases"} underline />
        {data?.map((i) => (
          <Stack mt={3} key={i.id} sx={{ textAlign: "left" }}>
            <Button variant={"text"} onClick={() => handleClick(i.id)}>
              <Typography
                color={"black"}
                fontWeight={"bold"}
                variant={"body1"}
                sx={{
                  ":hover": {
                    color: "primary.main",
                  },
                }}
              >
                {i.title}
              </Typography>
            </Button>
            <Divider />
          </Stack>
        ))}
      </Paper>
    </Box>
  );
};

export const DividedText = ({
  first,
  second,
  secondary,
  colored,
  onFirstClicked = () => {},
}) => {
  return (
    <Stack
      divider={
        <Divider
          orientation="vertical"
          flexItem
          sx={{ bgcolor: colored && "primary.main", width: 2 }}
        />
      }
      direction={"row"}
      spacing={2}
      mb={2}
    >
      <Typography
        variant={"body1"}
        color={secondary ? "primary" : "text.secondary"}
        fontWeight={secondary && "bold"}
        onClick={onFirstClicked}
        textTransform={"capitalize"}
        sx={{ cursor: "pointer" }}
      >
        {first}
      </Typography>

      <Typography
        variant={"body1"}
        color={secondary ? "primary" : "text.secondary"}
        fontWeight={secondary && "bold"}
        textTransform={"capitalize"}
      >
        {second}
      </Typography>
    </Stack>
  );
};

export const Content = () => {
  const navigate = useNavigate();
  const { data: news, loading } = useSelector((state) => state.news);
  const { data: pressReleases } = useSelector((state) => state.press);

  return (
    <Box
      sx={{
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
      }}
    >
      <Box sx={{ mx: { xs: "15px", md: "80px" }, py: { xs: 6, md: 12 } }}>
        {news.length !== 0 && (
          <>
            <Box>
              <Title title={"Most Recent News"} underline />
            </Box>
            <NewsCard
              loading={loading}
              height={news[0] ? { xs: 400, md: 800 } : 500}
              {...news[0]}
              onNewsClick={() => navigate(`/news/${news[0].id}`)}
            />
          </>
        )}

        {news.length === 0 || pressReleases.length === 0 ? null : (
          <Grid container spacing={{ xs: 2, md: 6 }} py={6}>
            <Grid
              item
              xs={12}
              md={3}
              sx={{ display: { xs: "none", md: "inherit" } }}
            >
              <PressCard flex={2} data={pressReleases} />
            </Grid>
            {news?.slice(1)?.map((item, index) => {
              const timeout = index + 1 * 1000;
              return (
                <Grid item xs={12} md={3} lg={3} key={item.id}>
                  <Grow
                    in={!loading}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(!loading ? { timeout: timeout } : {})}
                  >
                    <Box>
                      <NewsCard
                        loading={loading}
                        height={450}
                        {...item}
                        onNewsClick={() => navigate(`/news/${item.id}`)}
                      />
                    </Box>
                  </Grow>
                </Grid>
              );
            })}
          </Grid>
        )}

        <Stack pt={{ xs: 6 }}>
          <Newsletter />
        </Stack>
      </Box>
    </Box>
  );
};
