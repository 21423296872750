import {
  AccountCircleOutlined,
  FaceBook,
  Instagram,
  ShareOutlined,
  Twitter,
} from "../assets";
import { IconButton } from "@mui/material";
import { useCallback } from "react";

export const getIcon = (tag) => {
  switch (tag) {
    case "facebook":
      return <FaceBook />;
    case "insta":
      return <Instagram />;
    case "twitter":
      return <Twitter />;
    case "share":
      return <ShareOutlined />;
    default:
      return <AccountCircleOutlined />;
  }
};

const IconBtn = ({ icon, id, route, color }) => {
  const handleClick = useCallback(
    (e) => {
      const url = `https://${window.location.hostname}/${route}/${id}`;
      switch (icon) {
        case "facebook":
          window.open(
            route
              ? `https://www.facebook.com/sharer/sharer.php?u=${url}`
              : "https://web.facebook.com/GAMLSCOMMUNITY/?_rdc=1&_rdr"
          );
          return;
        case "insta":
          window.open("https://youtube.com/@gamlstv2866");
          return;
        default:
          window.open(
            route
              ? `https://www.twitter.com/intent/tweet/?url=${url}`
              : "https://twitter.com/GAMLSpb2"
          );
          return;
      }
    },
    [icon, id, route]
  );

  return (
    <IconButton sx={{ p: 0, mr: 2 }} onClick={handleClick} color="primary">
      {getIcon(icon)}
    </IconButton>
  );
};

export default IconBtn;
