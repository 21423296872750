import { Avatar } from "@mui/material";

function stringAvatar(name, small, smaller) {
  return smaller
    ? {
        sx: {
          bgcolor: "primary.main",
          width: 32,
          height: 32,
          fontSize: 12,
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      }
    : {
        sx: {
          bgcolor: "primary.main",
          width: small ? { xs: 83, md: 48 } : { xs: 120, md: 256 },
          height: small ? { xs: 83, md: 48 } : { xs: 120, md: 256 },
          fontSize: small ? { xs: 32, md: "inherit" } : { xs: 56, md: 120 },
          m: 1,
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
}

const LetterAvatar = ({ name, small, smaller }) => (
  <Avatar {...stringAvatar(name, small, smaller)} />
);

export default LetterAvatar;
