import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  getUser,
} from "../../data/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import User from "../../data/models/user";
import { useDispatch, useSelector } from "react-redux";
import { save } from "../../data/auth/authSlice";

export const useLogin = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
    retypePassword: "",
  });
  const [loading, error] = useAuthState(auth);
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user] = useAuthState(auth);
  const { user: localUser } = useSelector((state) => state.auth);
  useEffect(() => {}, [data]);
  const [regError, setRegError] = useState({ isError: false, msg: "" });

  const validateById = (id, value) => {
    switch (id) {
      case "phone":
        return value.match(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
        );
      case "email":
        return value.match(/^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}$/i);
      case "nationality":
        return value.match(/^[a-zA-Z]*$/);
      case "firstName":
        return value.match(/^[a-zA-Z]*$/);
      case "lastName":
        return value.match(/^[a-zA-Z]*$/);
      case "address":
        return value.match(/^[a-zA-Z]*$/);
      case "employer":
        return value.match(/^[a-zA-Z]*$/);
      case "password":
        return value.match(/^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,16}$/);
      case "retypePassword":
        return value.match(/^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,16}$/);
      case "specialty":
        return value.match(/^[a-zA-Z]*$/);
      default:
        return true;
    }
  };

  const handleOnNextClick = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleChange = (value, id) => {
    if (validateById(id, value)) {
      setRegError({ isError: false, msg: "" });
      const newUser = new User({
        ...localUser,
        [id]: value,
      });
      dispatch(save(newUser));
    } else {
      setRegError({ isError: true, msg: `${id.toUpperCase()} is not valid` });
    }
  };

  const handleOnSubmit = () => {
    if (data.password === data.retypePassword) {
      registerWithEmailAndPassword(data.email, data.password, localUser);
    }
  };

  return {
    setData,
    handleOnNextClick,
    activeStep,
    setActiveStep,
    dispatch,
    handleChange,
    handleOnSubmit,
    user,
    navigate,
    loading,
    auth,
    getUser,
    error,
    regError,
  };
};
