import { ArrowForwardIos } from "../../assets";
import {
  Box,
  Button,
  Grid,
  Grow,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Image from "mui-image";
import React, { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BestPractices,
  Brotherliness,
  Community,
  Professionalism,
  Background,
} from "../../assets/index";
import { Newsletter, Title, PartnerContent, NewsCard } from "../../components";
import BannerSlider from "../../components/bannerSlider";
import { memberBenefits, routePaths } from "../../constants";
import { useElementOnScreen } from "../../hooks/useElementOnScreen";

const MissionContent = (props) => {
  const { title, description, icon, bgColor, titleColor, descColor } = props;
  return (
    <Box
      flex={1}
      sx={{
        bgcolor: bgColor,
        p: { xs: "10px", md: "32px" },
        display: { xs: "flex" },
        alignItems: { xs: "center" },
        justifyContent: { xs: "space-between" },
      }}
    >
      <Box
        flex={0.3}
        alignItems={"center"}
        sx={{
          display: { xs: "none", md: "flex" },
        }}
      >
        <Image src={icon} width={70} />
      </Box>

      <Box
        flex={0.3}
        alignItems={"center"}
        sx={{
          display: { xs: "flex", md: "none" },
        }}
      >
        <Image src={icon} width={18} height={10} />
      </Box>

      <Box
        flex={0.7}
        flexDirection={"column"}
        justifyContent={"space-between"}
        sx={{
          color: "white",
          display: { xs: "flex", flexGrow: 1 },
        }}
      >
        <Typography
          variant={"h6"}
          gutterBottom
          color={titleColor}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          {title}
        </Typography>

        <Typography
          gutterBottom
          color={titleColor}
          sx={{ display: { xs: "flex", md: "none", fontSize: 10 } }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color={descColor}
          sx={{ display: { xs: "none", lg: "flex", md: "none" } }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const MissionBar = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: { xs: 1, md: 3 },
        display: { xs: "flex", md: "flex" },
        backdropFilter: "blur(30px)",
        background: "rgba(255, 216, 0, 0.2)",
        WebkitBackdropFilter: "blur(30px)",
        position: "absolute",
        left: { xs: 0, md: 80, sm: 40 },
        right: { xs: 0, md: 80, sm: 40 },
        bottom: { xs: 0, md: -100, sm: 0, lg: -100 },
        overflow: "hidden",
        height: "auto",
      }}
    >
      <Stack
        flexGrow={1}
        direction={"row"}
        sx={{
          display: { xs: "flex", flexGrow: 1 },
          minHeight: { xs: 52, md: 162 },
        }}
      >
        <MissionContent
          title={"Brotherliness"}
          description={
            "We look out for colleagues who are in need of support and care."
          }
          icon={Brotherliness}
          bgColor={"#F30813"}
        />
        <MissionContent
          title={"Professionalism"}
          description={
            "We are involved in promoting the body of knowledge in medical laboratory science."
          }
          icon={Professionalism}
          bgColor={"white"}
          titleColor={"#EF001B"}
          descColor={"#000000"}
        />

        <MissionContent
          title={"Best Practices"}
          description={
            "We do not compromise on laboratory best practices. We adhere strictly to this mandate."
          }
          icon={BestPractices}
          bgColor={"#FFD800"}
          titleColor={"#000000"}
          descColor={"#000000"}
        />
      </Stack>
    </Paper>
  );
};

const HeroContent = () => {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <BannerSlider />
      <MissionBar />
    </Box>
  );
};

const ReadMoreBtn = ({ link }) => {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(link);
  }, [navigate, link]);

  return (
    <Button
      variant={"contained"}
      sx={{ width: "fit-content", borderRadius: 3, my: 3 }}
      disableElevation
      startIcon={<ArrowForwardIos />}
      onClick={handleClick}
    >
      {"Read more"}
    </Button>
  );
};

const ContentCard = ({ data }) => {
  return (
    <Paper
      sx={{
        padding: "36px 20px",
        borderRadius: 5,
        width: { xs: "100%", md: "fit-content" },
      }}
      elevation={0}
    >
      <Stack direction={"row"} flexGrow={1} spacing={2}>
        <Box
          sx={{
            display: "flex",
            width: 60,
            height: 60,
            bgcolor: "#F57C7C",
            borderRadius: 3,
            p: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={data.icon}
            alt={`${data.title} icon`}
            width={40}
            height={40}
            loading={"lazy"}
          />
        </Box>
        <Stack flex={1}>
          <Title titleSecondary={data.title} subTitle={data.desc} noWrap />
        </Stack>
      </Stack>
    </Paper>
  );
};

const MemberContent = () => {
  const ref = useRef(null);
  const onScreen = useElementOnScreen(ref);
  return (
    <Stack
      direction={{ xs: "column", md: "row", lg: "row", sm: "row" }}
      spacing={{ lg: 6, md: 4, sm: 2, xs: 2 }}
      sx={{
        mx: { xs: "15px", md: "80px", sm: "40px" },
        my: { xs: 3, md: 16 },
      }}
      ref={ref}
    >
      <Stack flex={1} spacing={2} sx={{ mb: { xs: 8 } }}>
        <Stack>
          <Typography color={"#F30813"} variant={"body2"} gutterBottom>
            {"Get to Know Us"}
          </Typography>
          <Title title={"You Are Right with us"} underline />
        </Stack>

        <Typography variant={"body1"} gutterBottom>
          GAMLS’s objective is to promote science and to develop the practice of
          Medical Laboratory Science for the benefit of mankind and to
          collaborate with other health professionals to achieve optimal
          healthcare delivery in Ghana. We also aim to collaborate with the
          government and other relevant bodies or agencies in ensuring that
          medical laboratory services are comparable to best global practices.
        </Typography>

        <ReadMoreBtn link={routePaths.formation} />
      </Stack>

      <Stack flex={1} spacing={4}>
        <Stack>
          <Title
            titleSecondary={"Become one of us!"}
            subTitle={
              "GAMLS’s membership provides members with access to innovative tools, trainings and industry-wide collaboration."
            }
          />
        </Stack>
        <Grid
          container
          spacing={{ md: 3, sm: 1, lg: 4 }}
          rowSpacing={{ xs: 2, sm: 2, md: 3 }}
        >
          {memberBenefits.map((item, key) => {
            const timeout = key + 2 * 1000;
            return (
              <Grid
                key={key}
                xs={12}
                item
                md={12}
                sm={12}
                lg={item % 2 === 0 ? 6 : 5}
              >
                <Grow
                  in={onScreen}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(onScreen ? { timeout: timeout } : {})}
                >
                  <Box>
                    <ContentCard data={item} />
                  </Box>
                </Grow>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Stack>
  );
};

const CommunityContent = () => {
  const ref = useRef(null);
  const onScreen = useElementOnScreen(ref);
  return (
    <Box
      sx={{ display: { xs: "none", md: "flex" }, mx: "80px", my: 16, p: 0 }}
      ref={ref}
    >
      <Box flex={0.1}></Box>
      <Box flex={0.6}>
        <Title
          title={"From Our Community"}
          subTitle={
            "We are professionals with a common interest. We are a unit of professionals. We are a family."
          }
          underline
        />
        {/* <ReadMoreBtn /> */}
      </Box>
      <Box flex={0.1}></Box>
      <Box flex={1}>
        <Grow
          in={onScreen}
          style={{ transformOrigin: "0 0 0" }}
          {...(onScreen ? { timeout: 2000 } : {})}
        >
          <Box>
            <Image src={Community} />
          </Box>
        </Grow>
      </Box>
      <Box flex={0.1}></Box>
    </Box>
  );
};
const RecentContent = () => {
  const { data, loading } = useSelector((state) => state.news);
  const navigate = useNavigate();
  const recent = data.filter((x, index) => index > 4);

  return (
    <Box
      flexDirection={"column"}
      alignItems={"center"}
      sx={{
        display: { xs: "flex" },
        my: { xs: 4, md: 16 },
        px: { xs: "15px", md: "80px" },
        py: "50px",
        bgcolor: "#EAEAEA",
      }}
    >
      {recent.length !== 0 && (
        <Title
          title={"Recent News & Events"}
          underline
          xSpace
          alignMe
          alignHead
        />
      )}

      <Stack direction={"row"} sx={{ overflowX: "scroll" }}>
        {recent.map((news) => {
          return (
            <NewsCard
              loading={loading}
              height={450}
              {...news}
              onNewsClick={() => navigate(`/news/${news.id}`)}
            />
          );
        })}
      </Stack>

      <Newsletter />
    </Box>
  );
};

export const Content = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        background: "#F4F4F4",
      }}
    >
      <HeroContent />
      <MemberContent />
      <CommunityContent />
      <RecentContent />
      <PartnerContent />
    </Box>
  );
};
