import { CssBaseline } from "@mui/material";
import React from "react";
import { Banner } from "../../../components";
import { MissionContent } from "../aboutElements";

const Mission = () => {
  const bannerProps = {
    title: "About Us",
    otherTitle: "Mission & Vision",
    subTitle:
      "Brief Profile Of The Ghana Association Of Medical Laboratory Scientists",
  };
  return (
    <>
      <CssBaseline />
      <Banner {...bannerProps} />
      <MissionContent />
    </>
  );
};
export default Mission;
