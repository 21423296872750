import { SvgIcon } from "@mui/material";
import { ReactComponent as HttpsOutlined } from '../icons/ic_password.svg';

function HttpsOutlinedIcon(props){
    return  <SvgIcon
    {...props}
    component={HttpsOutlined}
    inheritViewBox
  />
}

export default HttpsOutlinedIcon