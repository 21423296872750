import { SvgIcon } from "@mui/material";
import { ReactComponent as EventAvailableOutlined } from '../icons/ic_event_available.svg';

function EventAvailableOutlinedIcon(props){
    return  <SvgIcon
    {...props}
    component={EventAvailableOutlined}
    inheritViewBox
  />
}

export default EventAvailableOutlinedIcon