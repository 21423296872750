import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { resourceConverter } from "../models/resourceModel";
import { saveData } from "./resourceSlice";

const readResources = () => {
  let list = [];
  return async (dispatch) => {
    const querySnapshot = await getDocs(
      collection(db, "cms/website/resources").withConverter(resourceConverter)
    );
    querySnapshot.forEach((doc) => {
      list.push({ ...doc.data(), id: doc.id });
    });
    dispatch(saveData(list));
  };
};

const updateResource = () => {
  return (dispatch) => {
    onSnapshot(
      collection(db, "cms/website/resources").withConverter(resourceConverter),
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {});
      }
    );
  };
};

const updateResources = () => {
  return (dispatch) => {
    onSnapshot(
      collection(db, "cms/website/resources").withConverter(resourceConverter),
      (querySnapshot) => {
        const resources = [];
        querySnapshot.forEach((doc) => {
          resources.push({ ...doc.data(), id: doc.id });
        });
        dispatch(saveData(resources));
      }
    );
  };
};

export { readResources, updateResource, updateResources };
