import { CssBaseline } from "@mui/material";
import UnderConstruction from "../../components/underConstruction";
import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { getLoginUrl, moodleAuth } from "../../data/moodle/moodleAction";
import { LoadingBackdrop } from "../../components";

const Learning = () => {
  const { user: localUser } = useSelector((state) => state.auth);

  const fetchData = useCallback(async () => {
    try {
      const res = await moodleAuth(localUser);
      console.log(res);
      if (res && res.success) {
        const loginUrl = await getLoginUrl(localUser.email);

        if (loginUrl) {
          window.location.href = loginUrl;
        } else {
          console.error("Error: Unable to obtain login URL");
        }
      } else {
        window.location.href = `https://gamls.org/learning`;
        // console.error("Error: Authentication failed");
      }
    } catch (error) {
      console.error("Error:", error.message || error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [localUser]);

  return (
    <>
      <CssBaseline />
      {/* <LearningBanner />
      <Content /> */}
      <LoadingBackdrop />
      {/* <UnderConstruction /> */}
    </>
  );
};
export default Learning;
