import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EventCard, FilterTab, Newsletter, Title } from "../../components";
import { regions } from "../../constants";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../data/firebase";

const Header = ({ data }) => {
  const navigate = useNavigate();
  const handleOnButtonClick = useCallback(() => {
    const url = `/events/${data.id}`;
    navigate(url);
  }, [data.id, navigate]);

  return (
    <Stack
      sx={{
        height: { xs: 284, md: 600 },
        px: { xs: 2, md: 8 },
        py: { xs: 4, md: 12 },
        mb: 3,
        color: "white",
        backgroundImage: `url(${data.img_url})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      flexGrow={1}
      justifyContent={"flex-end"}
    >
      <Typography variant={"h5"} fontWeight={"bold"} gutterBottom>
        {data.title}
      </Typography>
      <Button
        variant={"outlined"}
        sx={{
          px: 4,
          maxWidth: "fit-content",
          borderRadius: 5,
          textTransform: "initial",
          color: "white",
          fontWeight: "bold",
        }}
        onClick={handleOnButtonClick}
      >
        {"View Event"}
      </Button>
    </Stack>
  );
};

export const Content = () => {
  const { data: events, loading } = useSelector((state) => state.events);
  const [user] = useAuthState(auth);

  const [authorizedEvents, setauthorizedEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const eventsToShow = () => {
    let allEvents = events;
    if (!user) {
      allEvents = events.filter((event) => event.type !== "member");
    }
    setauthorizedEvents(allEvents);
  };

  useEffect(() => {
    eventsToShow();
  }, [user, events]);

  const currentEvent = authorizedEvents?.reduce((a, b) => {
    return new Date(a?.date) > new Date(b?.date) ? a : b;
  }, {});

  const handleComplete = useCallback(
    (params) => {
      authorizedEvents.forEach((event) => {
        params.forEach((param) => {
          if (Object.values(event).indexOf(param.toLowerCase()) > -1) {
            setFilteredEvents([...filteredEvents]);
          }
        });
      });
    },
    [events]
  );
  return (
    <Box
      sx={{
        px: { xs: 0, md: "140px" },
        mb: 6,
      }}
    >
      {Object.keys(currentEvent).length !== 0 && <Header data={currentEvent} />}
      <Stack sx={{ px: { xs: "15px", md: 24 }, my: 3 }}>
        <FilterTab
          disabled={events.length === 0}
          queryParam1={{ label: "Event in", params: regions }}
          queryParam2={{ label: "Type", params: ["Free", "Member", "General"] }}
          queryParam3={{ label: "Happening", params: ["Online", "In-person"] }}
          onComplete={(e) => handleComplete(e)}
        />
      </Stack>
      <Stack sx={{ px: { xs: "15px", md: 0 } }}>
        <Box sx={{ display: authorizedEvents.length === 0 && "none" }}>
          <Title underline title={"Most recent"} />
        </Box>

        <Grid
          container
          spacing={{ xs: 2, md: 8 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          py={{ xs: 4, md: 6 }}
        >
          {authorizedEvents
            ?.filter((x) => x?.id !== currentEvent?.id)
            ?.map((event, index) => {
              return (
                <Grid item xs={4} sm={4} md={3} key={event.id}>
                  <EventCard {...event} loading={loading} />
                </Grid>
              );
            })}
        </Grid>

        <Stack
          flexGrow={0}
          alignItems={"center"}
          my={6}
          sx={{ display: "none" }}
        >
          <Button
            variant={"contained"}
            disableElevation
            disabled={authorizedEvents.length === 0}
            sx={{
              bgcolor: "rgba(243, 8, 19, 0.31)",
              borderRadius: 5,
              width: "fit-content",
              textTransform: "initial",
            }}
          >
            <Typography
              component={"span"}
              sx={{ color: "#F30813", opacity: 1 }}
              fontWeight={"bold"}
            >
              {"View More Events"}
            </Typography>
          </Button>
        </Stack>
        <Newsletter />
      </Stack>
    </Box>
  );
};
