import { SvgIcon } from "@mui/material";
import { ReactComponent as Close } from '../icons/ic_close.svg';

function CloseIcon(props){
    return  <SvgIcon
    {...props}
    component={Close}
    inheritViewBox
  />
}

export default CloseIcon