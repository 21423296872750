import { Box, Paper, Stack, Typography } from "@mui/material";
import React from "react";

export const ExecutiveCard = ({ data, scroller }) => {
  return (
    <Paper
      sx={{
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        minWidth: { md: 250, lg: 300, xs: "100%", sm: 320 },
        height: { xs: 420, md: 360, lg: 420, sm: 400 },
        mr: scroller && { xs: 2, md: 4 },
        bgcolor: "white",
        backgroundImage: `url(${data.image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        justifyContent: "flex-end",
      }}
    >
      <Box
        sx={{
          color: "white",
          height: 97,
          bgcolor: "black",
          borderRadius: "20px",
          p: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant={"body1"} fontWeight={"bold"} textAlign={"center"}>
          {data.name}
        </Typography>
        <Typography>{data.position}</Typography>
      </Box>
    </Paper>
  );
};

const ExecutiveSlider = ({ executives = [] }) => {
  return (
    <Stack
      sx={{
        overflowX: "auto",
        display: "flex",
        flexDirection: "row",
        WebkitOverflowScrolling: "touch",
      }}
    >
      {executives?.map((e, i) => {
        return (
          <React.Fragment key={i}>
            <ExecutiveCard data={e} scroller />
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

export default ExecutiveSlider;
