export class NotificationModel {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.id = obj.id != null ? obj.id : "";
    this.title = obj.title != null ? obj.title : "";
    this.audience_type = obj.audience_type != null ? obj.audience_type : "";
    this.message = obj.message != null ? obj.message : "";
    this.date = obj.date != null ? obj.date : "";
    this.readBy = obj.readBy != null ? obj.readBy : [];
  }
}

export const notificationsConverter = {
  toFirestore: (notifications) => {
    return {
      id: notifications.id,
      title: notifications.title,
      audience_type: notifications.audience_type,
      message: notifications.message,
      date: notifications.date,
      readBy: notifications.readBy,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new NotificationModel({
      id: data.id,
      title: data.title,
      readBy: data.readBy,
      audience_type: data.audience_type,
      message: data.message,
      date: new Date(data?.date?.seconds * 1000).toLocaleDateString(),
    });
  },
};
