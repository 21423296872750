export class LessonModel {
    constructor(obj) {
      obj = obj != null ? obj : {};
      this.id = obj.id != null ? obj.id : "";
      this.category = obj.category != null ? obj.category : "";
      this.course = obj.course != null ? obj.course : "";
      this.name = obj.name != null ? obj.name : "";
      this.video_url = obj.video_url != null ? obj.video_url : "";
      this.description = obj.description != null ? obj.description : "";
      this.questions = obj.questions != null ? obj.questions : [];
      this.questions_count =
        obj.questions_count != null ? obj.questions_count : 0;
      this.date = obj.date != null ? obj.date : "";
      this.date_modified = obj.date_modified != null ? obj.date_modified : "";
    }
  }
  
  export const lessonConverter = {
    toFirestore: (lesson) => {
      return {
        id: lesson.id,
        category: lesson.category,
        course: lesson.course,
        name: lesson.name,
        video_url: lesson.video_url,
        questions: lesson.questions,
        questions_count: lesson.questions_count,
        description: lesson.description,
        date: lesson.date,
        date_modified: lesson.date_modified,
      };
    },
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new LessonModel({
        id: data.id,
        category: data.category,
        course: data.course,
        name: data.name,
        video_url: data.video_url,
        questions: data.questions,
        questions_count: data.questions ? data.questions.length : 0,
        description: data.description,
        date: data?.date?.seconds
          ? new Date(data?.date?.seconds * 1000).toLocaleDateString()
          : data.date,
        date_modified: data.date_modified,
      });
    },
  };
  