import {
  collection,
  onSnapshot,
  query,
  where,
  getDocs,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../firebase";
import {
  clearLessons,
  initializeLessons,
  learningRequest,
  lessonsFail,
} from "./learningSlice";
import { nameConverter } from "../models/nameModel";
import {
  categoriesFail,
  categoriesRequest,
  saveCategories,
} from "./categoriesSlice";
import { coursesFail, coursesRequest, saveCourses } from "./coursesSlice";

const fetchCategories = () => {
  return (dispatch) => {
    dispatch(categoriesRequest());
    try {
      onSnapshot(
        collection(db, "cms/learning/categories").withConverter(nameConverter),
        (querySnapshot) => {
          const resources = [];
          querySnapshot.forEach((doc) => {
            resources.push({ ...doc.data(), id: doc.id });
          });
          getCoursesCount(resources, dispatch);
        }
      );
    } catch (e) {
      dispatch(categoriesFail());
    }
  };
};

const fetchCoursesByCategory = (category) => {
  return async (dispatch) => {
    dispatch(coursesRequest());
    const courses = [];
    try {
      const q = query(
        collection(db, "cms/learning/courses"),
        where("other".toLowerCase(), "==", category.toLowerCase())
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        courses.push({ ...doc.data(), id: doc.id });
      });
      dispatch(saveCourses(courses));
    } catch (e) {
      dispatch(coursesFail());
    }
  };
};

const fetchLessonsByCourse = (course) => {
  return async (dispatch) => {
    dispatch(learningRequest());
    try {
      const lessons = [];
      const q = query(
        collection(db, "cms/learning/lessons"),
        where("course".toLowerCase(), "==", course.toLowerCase())
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        lessons.push({ ...doc.data(), id: doc.id });
      });
      dispatch(initializeLessons(lessons));
    } catch (e) {
      dispatch(lessonsFail());
    }
  };
};

const getCoursesCount = async (data, dispatch) => {
  let arr = [];
  await Promise.all(
    data.map(async (cat) => {
      const catName = cat.name.toLowerCase();
      const q = query(
        collection(db, "cms/learning/courses"),
        where("other".toLowerCase(), "==", catName)
      );
      const snapshot = await getCountFromServer(q);
      arr.push({ ...cat, other: snapshot.data().count });
    })
  );
  dispatch(saveCategories(arr));
};

const clearCoursLessons = () => {
  return (dispatch) => {
    dispatch(clearLessons());
  };
};

export {
  fetchLessonsByCourse,
  fetchCategories,
  fetchCoursesByCategory,
  clearCoursLessons,
};
