import { IconButton, InputAdornment, TextField } from "@mui/material";
import { VisibilityOff, VisibilityOn } from "../assets";
import { useState } from "react";
import InputText from "./form/inputText";

const PasswordField = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  let toggleEye = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label={showPassword ? "Hide password" : "Show password"}
          onClick={togglePasswordVisibility}
          edge="end"
        >
          {showPassword ? <VisibilityOn /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    ),
  };
  return props.mode === "input" ? (
    <InputText
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={toggleEye}
    />
  ) : (
    <TextField
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={toggleEye}
    />
  );
};

export default PasswordField;
