import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "../../constants/appConstants";

const initialState = {
  loading: false,
  data: [],
};

export const categoriesSlice = createSlice({
  name: reducers.categories,
  initialState,
  reducers: {
    categoriesRequest(state) {
      state.loading = true;
    },
    saveCategories: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    updateCategories: (state, action) => {
      state.data = [...state.data, action.payload];
      state.loading = false;
    },
    clearCategories: (state) => {
      state.data = [];
      state.loading = false;
    },
    categoriesFail: (state) => {
      state.loading = false;
    },
  },
});

export const {
  saveCategories,
  clearCategories,
  updateCategories,
  categoriesRequest,
  categoriesFail,
} = categoriesSlice.actions;

export default categoriesSlice;
