import React from "react";
import { Button, Modal, Box, TextField, Typography } from "@mui/material";

import { useFormik } from "formik";

const CustomModal = ({ open, onClose, item, action }) => {
  const formik = useFormik({
    initialValues: item.initialValues,
    validationSchema: item.validation,
    // enableReinitialize: true,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);
      action(values);
      actions.setSubmitting(false);
    },
  });
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: 600,
          p: 6,
          bgcolor: "background.paper",
          borderRadius: 4,
          outline: "none",
        }}
      >
        <Typography variant="h5" component="div" fontWeight={"bold"}>
          {item.header}
        </Typography>

        {item.data.map((field, i) => {
          return (
            <TextField
              fullWidth
              margin="normal"
              key={field.id}
              value={formik.values[field.id] ?? ""}
              id={field.id}
              placeholder={field.placeholder}
              disabled={field.disabled}
              label={field.placeholder}
              type={field.type}
              onChange={formik.handleChange}
              helperText={formik.errors[field.id]}
              error={
                formik.touched[field.id] && Boolean(formik.errors[field.id])
              }
            />
          );
        })}

        <Button
          onClick={formik.handleSubmit}
          variant="contained"
          sx={{ mt: 2 }}
          color="primary"
        >
          {item.buttonText}
        </Button>
      </Box>
    </Modal>
  );
};

export default CustomModal;
