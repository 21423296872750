import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Title } from "../components";
import { blogs } from "../constants";

const BlogCard = ({ image, title, date, author }) => {
  return (
    <Card
      sx={{
        borderRadius: "20px",
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          alt="event image"
          height="200"
          image={image}
          loading={"lazy"}
        />

        <CardContent
          sx={{
            color: "#707070",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Typography variant={"caption"}>{date}</Typography>
          <Typography variant={"caption"}>{author}</Typography>
          <Typography variant={"overline"} fontWeight={"bold"}>
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const BlogList = ({ noPadding }) => {
  return (
    <Box
      sx={{
        display: { xs: "flex" },
        mx: noPadding || { xs: "15px", md: "80px" },
        my: { xs: 6, md: 16 },
        p: 0,
      }}
      flexDirection={"column"}
    >
      <Title
        title={"Raising the Voice of Our Associates"}
        subTitle={"Blogs by our members"}
        underline
      />

      <Grid
        container
        spacing={{ md: 6 }}
        sx={{ display: { md: "inherit", xs: "none" } }}
      >
        {blogs?.map((topic, index) => {
          return (
            <Grid item container key={index} md>
              <BlogCard {...topic} />
            </Grid>
          );
        })}
      </Grid>

      <Stack
        direction={"row"}
        sx={{ display: { xs: "flex", md: "none" }, overflowX: "scroll" }}
      >
        {blogs?.map((topic, index) => {
          return (
            <Box
              sx={{ minWidth: "80%", pr: 2, my: 2, borderRadius: "20px" }}
              key={index}
            >
              <BlogCard {...topic} />
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default BlogList;
