import { SvgIcon } from "@mui/material";
import { ReactComponent as TransgenderOutlined } from '../icons/ic_transgender.svg';

function TransgenderOutlinedIcon(props){
    return  <SvgIcon
    {...props}
    component={TransgenderOutlined}
    inheritViewBox
  />
}

export default TransgenderOutlinedIcon