import { Box, Container } from "@mui/material";
import { Notfound } from "../../assets";

const NotFound = () => {
  return (
    <Container sx={{ p: { xs: 6, md: 12 } }}>
      <Box
        component={"img"}
        src={Notfound}
        sx={{
          width: "100%",
          height: { xs: 300, sm: 300, md: 400, lg: 400, xl: 700 },
        }}
      />
    </Container>
  );
};

export default NotFound;
