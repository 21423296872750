import { CssBaseline } from "@mui/material";
import React, { useEffect } from "react";
import { Content } from "./electionElements";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { NavBar } from "../../components";
import { routePaths } from "../../constants";

const Elections = () => {
  const location = useLocation();
  const isVerified = location?.state?.isVerified;
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!isVerified) {
      navigate(routePaths.electionsLogin);
    }
  }, []);

  return (
    <>
      <CssBaseline />
      <NavBar />
      <Content isVerified={isVerified} id={id} />
    </>
  );
};
export default Elections;
