import React from "react";
import { CustomModal } from "../../components";
import { eventRegSteps } from "../../constants";
import { eventRegisterUser } from "../../data/firebase";

const EventRegisterModal = ({ open, onClose, eventId }) => {
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      submitAction={2}
      item={eventRegSteps}
      action={(data) => eventRegisterUser(eventId, data, onClose)}
    />
  );
};

export default EventRegisterModal;
