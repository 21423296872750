import { routePaths } from "./constants";
import { DetailedEvent } from "./pages/events/detailedEvent";
import Home from "./pages/home";
import Branches from "./pages/branches";
import News from "./pages/news";
import Fund from "./pages/fund";
import Resources from "./pages/resources";
import Formation from "./pages/about/formation";
import Contact from "./pages/contact";
import Faq from "./pages/faq";
import Jobs from "./pages/jobs";
import Organizations from "./pages/organizations";
import Events from "./pages/events";
import Mission from "./pages/about/mission";
import ExecutiveCouncil from "./pages/about/council";
import NotFound from "./pages/notFound";
import Profile from "./pages/profile";
import Elections from "./pages/elections";
import JobDetails from "./pages/jobs/jobDetails";
import NewsDetails from "./pages/news/newsDetails";
import Help from "./pages/profile/help";
import Learning from "./pages/learning";
import LearningDetails from "./pages/learning/learningDetails";

const {
  home,
  branches,
  news,
  fund,
  resources,
  formation,
  mission,
  contact,
  faq,
  jobs,
  job,
  orgs,
  events,
  event,
  executive,
  profile,
  elections,
  newsDetails,
  help,
  reset,
  learning,
  learningDetails,
} = routePaths;

const routes = [
  { path: home, name: "Home", component: Home, protected: false },
  { path: branches, name: "Branches", component: Branches, protected: false },
  { path: news, name: "News & PR", component: News, protected: false },
  {
    path: newsDetails,
    name: "News Details",
    component: NewsDetails,
    protected: false,
  },

  {
    path: resources,
    name: "Resources",
    component: Resources,
    protected: false,
  },
  // {
  //   path: reset,
  //   name: "Reset Password",
  //   component: Reset,
  //   protected: false,
  // },
  {
    path: formation,
    name: "Formation",
    component: Formation,
    protected: false,
  },
  {
    path: mission,
    name: "Mission & Vision",
    component: Mission,
    protected: false,
  },
  {
    path: executive,
    name: "Executive Council",
    component: ExecutiveCouncil,
    protected: false,
  },
  { path: events, name: "Events", component: Events, protected: false },
  { path: event, name: "Event", component: DetailedEvent, protected: false },
  { path: contact, name: "Contact Us", component: Contact, protected: false },
  { path: faq, name: "FAQ", component: Faq, protected: false },
  { path: help, name: "Help", component: Help, protected: false },
  {
    path: orgs,
    name: "Affiliate Organizations",
    component: Organizations,
    protected: false,
  },
  { path: jobs, name: "Jobs", component: Jobs, protected: true },
  { path: job, name: "Job", component: JobDetails, protected: true },
  { path: learning, name: "Learning", component: Learning, protected: true },
  {
    path: learningDetails,
    name: "Learning Details",
    component: LearningDetails,
    protected: true,
  },
  { path: profile, name: "Profile", component: Profile, protected: true },
  { path: fund, name: "GAMLS Fund", component: Fund, protected: true },
  {
    path: elections,
    name: "Elections",
    component: Elections,
    protected: true,
  },

  { path: "*", name: "Not Found", component: NotFound, protected: false },
];

export default routes;
