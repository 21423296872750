import { SvgIcon } from "@mui/material";
import { ReactComponent as AccountCircleOutlinedIcon } from '../icons/ic_user.svg';

function AccountCircleOutlined(props){
    return  <SvgIcon
    {...props}
    component={AccountCircleOutlinedIcon}
    inheritViewBox
  />
}

export default AccountCircleOutlined