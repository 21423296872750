import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import TransitionAlerts from "../../components/transitionAlerts";
import InputText from "../../components/form/inputText";
import { PrimaryButton, TextButton } from "../../components/buttons";
import { useFormik } from "formik";
import PasswordStrengthBar from "react-password-strength-bar";
import { passwordReset } from "../../data/firebase";
import { resetValidationSchema } from "../../utils/validationSchema";
import { PasswordField } from "../../components";

export const ResetContent = React.memo(({ navigate, title, subtitle }) => {
  const [error, setError] = React.useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const oobCode = urlParams.get("oobCode");

  const formik = useFormik({
    initialValues: {
      password: "",
      retypePassword: "",
    },
    validationSchema: resetValidationSchema,
    onSubmit: async (values, actions) => {
      formik.setSubmitting(true);
      await passwordReset(oobCode, values.password, navigate);
      formik.setSubmitting(false);
    },
  });

  return (
    <Stack
      component={"form"}
      spacing={{ xs: 3, md: 3 }}
      onSubmit={formik.handleSubmit}
      p={{ xs: "10px", md: "8%" }}
    >
      <Box>
        <Typography
          sx={{
            m: { md: "10px 0px" },
          }}
          variant={"h5"}
          fontWeight={"bold"}
        >
          {title}
        </Typography>

        <Typography gutterBottom variant={"body2"}>
          {subtitle}
        </Typography>
      </Box>

      <Box>
        <PasswordField
          mode={"input"}
          placeholder={"New Password"}
          value={formik.values.password}
          onChange={formik.handleChange}
          helperText={formik.errors.password}
          name={"password"}
          type={"password"}
          error={formik.touched.password && Boolean(formik.errors.password)}
        />
        {formik.values.password ? (
          <PasswordStrengthBar password={formik.values.password} />
        ) : null}
      </Box>
      <PasswordField
        mode={"input"}
        placeholder={"Re-type Password"}
        value={formik.values.retypePassword}
        onChange={formik.handleChange}
        helperText={formik.errors.retypePassword}
        name={"retypePassword"}
        type={"password"}
        error={
          formik.touched.retypePassword && Boolean(formik.errors.retypePassword)
        }
      />

      <TransitionAlerts
        message={error}
        severity={"error"}
        onClose={() => setError("")}
      />

      <PrimaryButton type={"submit"} loading={formik.isSubmitting}>
        Reset Password
      </PrimaryButton>

      <Stack direction={"row"} alignItems={"center"}>
        <Typography sx={{ textTransform: "initial" }} variant={"button"}>
          Don't have an account?
        </Typography>
        <TextButton
          color={"primary"}
          onClick={() => navigate("/login?reg_stg=0")}
        >
          Register here
        </TextButton>
      </Stack>
    </Stack>
  );
});
