import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "../../constants/appConstants";

const initialState = {
  data: [],
  loading: false,
};

export const newsSlice = createSlice({
  name: reducers.news,
  initialState,
  reducers: {
    init: (state) => {
      state.loading = true;
    },
    saveData: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    updateData: (state, action) => {
      state.data = [...state.data, action.payload];
      state.loading = false;
    },
    clearData: (state) => {
      state.data = [];
      state.loading = false;
    },
  },
});

export const { saveData, clearData, updateData, init } = newsSlice.actions;

export default newsSlice;
