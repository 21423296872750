import { Box, Paper, Stack } from "@mui/material";
import React from "react";
import { partners } from "../constants";
import Slider from "react-slick-slider";
import "../index.css";
import Title from "./title";

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 4,
  speed: 500,
  autoplay: true,
};

const PartnerContent = () => {
  return (
    <Stack
      sx={{ mx: { xs: "15px", md: "80px" }, my: { xs: 3, md: 16 } }}
      spacing={{ xs: 1, md: 2 }}
    >
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <style>{cssstyle}</style>
      <Title title={"Partners & Sponsors"} underline />

      <Paper
        sx={{
          py: 2,
          height: "fit-content",
          borderRadius: 5,
          px: 3,
        }}
      >
        <Slider {...settings}>
          {partners.map((src, i) => {
            return (
              <div key={i} className={"slide"}>
                <Box
                  component={"img"}
                  src={src}
                  alt={"partner logo"}
                  width={{ xs: 48, md: 100, sm: 80, lg: 150 }}
                  loading={"lazy"}
                />
              </div>
            );
          })}
        </Slider>
      </Paper>
    </Stack>
  );
};

export default PartnerContent;

const cssstyle = `
.container {
  margin: 0 auto;
  padding: 0px 40px 40px 40px;
}
img {
    margin: 10px;
    padding: 2%;
    position: relative;
}
.slick-next:before, .slick-prev:before {
    color: #000;
}
.center .slick-center img {
    opacity: 1;
    -ms-transform: scale(1.08);
    transform: scale(1.08);
}
.center img {
    transition: all .10s ease;
}
`;
