import axios from "axios";

const axiosConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
const galmsSite = "https://learning.gamls.org";
const wstoken = "35e5dc87f5317e8e523a7846de314353";
const moodleGetUsersUrl = `${galmsSite}/webservice/rest/server.php`;

// const moodleLogin = async (username, password) => {
//   const moodleLoginUrl = `${galmsSite}/login/token.php?username=${username}&password=${password}&service=moodle_mobile_app`;
//   //   const moodleLoginUrl = `${galmsSite}/login/token.php?username=${username}&password=${password}&service=moodle_mobile_app`;
//   const res = await axios.post(moodleLoginUrl);

//   const { token, error } = res.data;

//   if (error) {
//     return {
//       success: false,
//       msg: new Error("Error during login:", error),
//     };
//   }

//   return {
//     success: true,
//     msg: token,
//   };
// };

const checkMoodleUserExists = async (username) => {
  const data = {
    criteria: [
      {
        key: "username",
        value: username,
      },
    ],
    wsfunction: "core_user_get_users",
    moodlewsrestformat: "json",
    wstoken: wstoken,
  };

  const response = await axios.post(moodleGetUsersUrl, data, axiosConfig);

  const users = response.data.users || [];

  if (users.length > 0) {
    return { exists: true, msg: users[0] };
  } else {
    return { exists: false, msg: "User does not exist" };
  }
};

const moodleAuth = async (user) => {
  const username = user.email;
  const password = user.memberId;

  if (user.activated) {
    const res = await checkMoodleUserExists(username);
    if (!res.exists) {
      const new_user = {
        username,
        password,
        email: user.email,
        firstname: user.firstName,
        lastname: user.lastName,
        phone: user.phone,
        region: user.region,
        address: user.address,
        staffId: user.staffId,
        gender: user.gender,
      };
      const moodleUser = moodleCreator(new_user, galmsSite, wstoken);
      return moodleUser;
    }
    // const loginRes = await moodleLogin(username, password);

    return { success: true };
  } else {
    // User is not activated, return failure
    return { success: false, message: "User is not activated" };
  }
};

export async function moodleCreator(user, galmsSite, apiToken) {
  const {
    username,
    password,
    email,
    firstname,
    lastname,
    phone,
    region,
    address,
    staffId,
    gender,
  } = user;

  // Define the user data
  const userData = {
    wstoken: apiToken,
    wsfunction: "core_user_create_users",
    moodlewsrestformat: "json",
    "users[0][username]": username,
    "users[0][firstname]": firstname,
    "users[0][lastname]": lastname,
    "users[0][email]": email,
    "users[0][password]": password,
    "users[0][phone1]": phone,
    "users[0][address]": address,
    "users[0][customfields][0][type] ": "region",
    "users[0][customfields][0][value] ": region,
    "users[0][customfields][0][type] ": "address",
    "users[0][customfields][0][value] ": address,
    "users[0][customfields][0][type] ": "staffId",
    "users[0][customfields][0][value] ": staffId,
    "users[0][customfields][0][type] ": "gender",
    "users[0][customfields][0][value] ": gender,
  };

  // Make a POST request to create the user
  //   const postData = querystring.stringify(userData);

  const res = axios
    .post(`${galmsSite}/webservice/rest/server.php`, userData, axiosConfig)
    .then((response) => {
      if (response.status === 200) {
        const data = response.data;

        if (data.error) {
          return { message: data.error, success: false };
        } else if (data.message) {
          return { message: data.debuginfo, success: false };
        } else {
          const user = data[0];
          user.username = username;
          user.password = password;

          return {
            message: `You have successfully been registered on the GAMLS eLearning platform.  `,
            success: true,
          };
        }
      } else {
        return { message: response.status, success: false };
      }
    })
    .catch((error) => {
      return { message: error, success: false };
    });
}

async function getLoginUrl(username) {
  const param = {
    user: {
      username: username,
      email: username,
    },
    wsfunction: "auth_userkey_request_login_url",
    moodlewsrestformat: "json",
    wstoken: wstoken,
  };

  try {
    const response = await axios.post(moodleGetUsersUrl, param, axiosConfig);

    const data = await response.data;

    if (data && data.loginurl) {
      const loginurl = data.loginurl;
      console.log(data);
      return loginurl;
    }
  } catch (error) {
    console.error("Error:", error);
    return "";
  }

  // return false;
}

export { moodleAuth, getLoginUrl };
