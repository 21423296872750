import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import routes from "./routes";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Outlet,
} from "react-router-dom";
import { Footer, NavBar, ProtectedRoute } from "./components";
import { routePaths } from "./constants/index";
import Login from "./pages/login/index";
import ScrollToTop from "./utils/scrollToTop";
import ElectionLogin from "./pages/elections/electionLogin";
import Elections from "./pages/elections";
import ErrorBoundary from "./pages/errorBoundary";
import Reset from "./pages/reset";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F30813",
    },
    background: {
      default: "#F4F4F4",
    },
  },
});

theme.typography.h5 = {
  fontSize: "1rem",
  "@media (min-width:600px)": {
    fontSize: "1.3rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.8rem",
  },
};

function AppLayout() {
  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
}

function AuthLayout() {
  return <Outlet />;
}

function ElectionLayout() {
  return (
    <>
      <Outlet />
      <Footer />
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path={routePaths.home} element={<AppLayout />}>
              {routes.map((route) => (
                <Route
                  key={route.name}
                  index={route.path === routePaths.home}
                  exact={route.path !== routePaths.profile}
                  path={route.path}
                  element={
                    route.protected ? (
                      <ProtectedRoute>
                        <route.component />
                      </ProtectedRoute>
                    ) : (
                      <route.component />
                    )
                  }
                />
              ))}
            </Route>

            <Route path={routePaths.login} element={<AuthLayout />}>
              <Route index element={<Login />} />
            </Route>
            <Route path={routePaths.login} element={<AuthLayout />}>
              <Route index element={<Login />} />
            </Route>
            <Route path={routePaths.reset} element={<AuthLayout />}>
              <Route index element={<Reset />} />
            </Route>

            <Route
              path={routePaths.elections}
              element={
                <ProtectedRoute>
                  <ElectionLayout />
                </ProtectedRoute>
              }
            >
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Elections />
                  </ProtectedRoute>
                }
                path={routePaths.elections}
              />
              <Route
                element={
                  <ProtectedRoute>
                    <Elections />
                  </ProtectedRoute>
                }
                path={routePaths.electionSection}
              />
              <Route
                exact
                element={
                  <ProtectedRoute>
                    <ElectionLogin />
                  </ProtectedRoute>
                }
                path={routePaths.electionsLogin}
              />
            </Route>
          </Routes>
        </Router>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
