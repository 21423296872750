import {
  Banner,
  FilterTab,
  JobCard,
  Newsletter,
  Title,
} from "../../components/index";
import { Box, Stack, CssBaseline } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJobs } from "../../data";
import { regions } from "../../constants";

const Jobs = () => {
  const bannerProps = {
    title: "Jobs & Recruitment",
    subTitle:
      "Find the right job vacancies. Providing Access to job openings in medical laboratory by our Stakeholders with GALMS job recruitment portal",
  };
  const dispatch = useDispatch();
  const { data: jobs } = useSelector((state) => state.job);
  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(getJobs());
    }, 2000);
    return () => clearTimeout(getData);
  }, [dispatch]);

  return (
    <>
      <CssBaseline />
      <Banner {...bannerProps} />
      <Box
        flexGrow={1}
        sx={{
          minWidth: "100%",
          py: { xs: 1, md: 6 },
          px: { xs: "15px", md: "140px" },
          pb: 16,
        }}
      >
        <Stack sx={{ px: { xs: "15px", md: 24 }, my: 3 }}>
          <FilterTab
            disabled={jobs.length === 0}
            queryParam1={{ label: "Located in", params: regions }}
            queryParam2={{
              label: "Type",
              params: ["Part time", "Full time", "Contract"],
            }}
            queryParam3={{
              label: "Level",
              params: [
                "Beginner",
                "Novice",
                "Intermediate",
                "Advanced",
                "Specialist",
              ],
            }}
          />
        </Stack>

        <Stack pb={12} spacing={{ xs: 2, md: 3 }} pt={{ xs: 2, md: 0 }}>
          <Box sx={{ display: jobs.length === 0 && "none" }}>
            <Title title={"Most Recent Jobs"} underline xSpace />
          </Box>

          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 6, md: 12 }}
            sx={{ pb: 6 }}
          >
            {jobs.map((job) => {
              return (
                <React.Fragment key={job.id}>
                  <JobCard data={job} />
                </React.Fragment>
              );
            })}
          </Stack>
        </Stack>
        <Newsletter />
      </Box>
    </>
  );
};
export default Jobs;
