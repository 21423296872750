import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { newsConverter } from "../models/newsModel";
import { init, saveData } from "./newsSlice";

const updateNews = () => {
  return (dispatch) => {
    dispatch(init());
    onSnapshot(
      collection(db, "cms/website/news").withConverter(newsConverter),
      (querySnapshot) => {
        const resources = [];
        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          if (!docData.disabled) {
            resources.push({ ...docData, id: doc.id });
          }
        });
        dispatch(saveData(resources));
      }
    );
  };
};

export { updateNews };
