import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import Image from "mui-image";
import React, { useCallback } from "react";
import { ArrowBackIos, GamlsLogoRed, Hero } from "../../assets";
import { useLocation, useNavigate } from "react-router-dom";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ResetContent } from "./resetContent";

export default function Reset() {
  const navigate = useNavigate();

  const resetProps = {
    title: "Reset Password",
    subtitle: " Enter your your new password to reset.",
  };

  const matches = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );
  const handleBackClick = useCallback(() => navigate("/"), [navigate]);

  return (
    <Stack className={"highter"} direction={"row"} sx={{ overflow: "hidden" }}>
      <Box flex={1} sx={{ display: { xs: "none", sm: "inherit" } }}>
        <Image width={"100%"} height={"100vh"} src={Hero} />
      </Box>
      <Stack flex={{ xs: 1, md: 0.5 }} className={matches ? null : "login-bg"}>
        <Stack p={"5%"} spacing={2} alignItems={"flex-start"}>
          <Tooltip title={"Back to Home"}>
            <IconButton color={"primary"} onClick={handleBackClick}>
              <ArrowBackIos />
            </IconButton>
          </Tooltip>
          <Box
            component={"img"}
            src={GamlsLogoRed}
            sx={{
              width: { xs: 160, md: 220, sm: 200, lg: 200 },
              height: { xs: 70, md: 100, sm: 80, lg: 90 },
            }}
          />
        </Stack>
        <ResetContent {...resetProps} navigate={navigate} />
      </Stack>
    </Stack>
  );
}
