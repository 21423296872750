import { CssBaseline } from "@mui/material";
import React from "react";
import { Banner } from "../../../components";
import { ExecutiveContent } from "../aboutElements";

const bannerProps = {
  title: "About Us",
  otherTitle: "Executive Council",
  subTitle:"The Association is led by a dedicated team of professionals who are given a two year mandate to serve."
};

const ExecutiveCouncil = () => {
  return (
    <>
      <CssBaseline />
      <Banner {...bannerProps} />
      <ExecutiveContent />
    </>
  );
};

export default ExecutiveCouncil;
