import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useCallback } from "react";

const CustomSelector = ({ values, label, secondary, onSelect, disabled }) => {
  const [type, setType] = React.useState("");

  const handleChange = useCallback(
    (event) => {
      if (event.target.value === "") {
        setType("");
      }
      setType(event.target.value);
      onSelect(event.target.value);
    },
    [onSelect]
  );
  return (
    <FormControl
      variant={"standard"}
      disabled={disabled}
      sx={{
        m: 1,
        width: secondary ? "100%" : 200,
        ".MuiFormLabel-root": {
          color: "white",
        },
        "& label.Mui-focused": {
          color: "white",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "white",
        },
        "& .MuiInput-underline:before": {
          borderBottomColor: "white",
        },
      }}
    >
      <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        label={label}
        value={type}
        onChange={handleChange}
        sx={
          secondary && {
            color: "white",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
              color: "white",
            },
            ".MuiSelect-icon": {
              color: "white",
            },
            ".MuiSelect-outlined": {
              color: "white",
              borderColor: "white",
            },
            ".MuiSelect-standard": {
              color: "white",
              borderColor: "white",
            },
            ".MuiSelect-nativeInput": {
              color: "white",
              borderColor: "white",
            },

            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
              color: "white",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
              color: "white",
            },
          }
        }
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {values?.map((item, idx) => {
          return (
            <MenuItem key={idx} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CustomSelector;
