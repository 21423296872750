import { Box, Stack } from "@mui/material";
import { useCallback } from "react";

const ProgressIndicator = ({ activeIndex, count, onClick }) => {
  let array = new Array(count).fill(" ");

  const handleClick = useCallback(
    (index) => {
      onClick(index);
    },
    [onClick]
  );

  return (
    <Stack spacing={1} alignItems={"center"}>
      {array.map((_, index) => {
        return (
          <Box
            key={index}
            onClick={() => handleClick(index)}
            sx={{
              width: activeIndex === index ? 16 : 4,
              height: 16,
              bgcolor: "white",
              opacity: activeIndex >= index || 0.48,
              borderRadius: activeIndex === index ? "50%" : 8,
            }}
          ></Box>
        );
      })}
    </Stack>
  );
};

export default ProgressIndicator;
