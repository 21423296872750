import { CssBaseline } from "@mui/material";
import { Banner } from "../../components";
import { Content } from "./fundElements";

const Fund = () => {
  const bannerProps = {
    title: "GAMLS Fund",
    subTitle:
      "We help fellow nonprofits access the funding, tools, training, and support they need to serve their communities.",
  };
  return (
    <>
      <CssBaseline />
      <Banner {...bannerProps} />
      <Content />
    </>
  );
};
export default Fund;
