import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "../../constants/appConstants";

const initialState = {
  loading: false,
  courses: [],
};

export const coursesSlice = createSlice({
  name: reducers.courses,
  initialState,
  reducers: {
    coursesRequest(state) {
      state.loading = true;
    },
    saveCourses: (state, action) => {
      state.courses = action.payload.sort(
        (x, y) => x.date.seconds - y.date.seconds
      );
      state.loading = false;
    },
    updateCourses: (state, action) => {
      state.courses = [...state.courses, action.payload];
      state.loading = false;
    },
    clearCourses: (state) => {
      state.courses = [];
      state.loading = false;
    },
    coursesFail(state) {
      state.loading = false;
    },
  },
});

export const {
  saveCourses,
  clearCourses,
  updateCourses,
  coursesRequest,
  coursesFail,
} = coursesSlice.actions;

export default coursesSlice;
