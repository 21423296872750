import { Box, Card, CssBaseline, Grid, Stack, Tab, Tabs } from "@mui/material";
import { AltBanner, Title } from "../../components";
import { LoginContent } from "../login/loginContent";
export const MemberToolsAccess = () => {
  const altBannerProps = {
    title: "Advancing Medical Laboratory",
    color: "#F5AAA9",
    otherTitle:
      "GALMS vision is to promote science and to develop the practice o Medical Laboratory Science for the benefit of mankind and to collaborate with other health professionals to achieve optimal healthcare delivery in Ghana.",
  };
  const loginProps = {
    title: "Login as a Member",
    color: "#F5AAA9",
    subtitle:
      "For your exclusiveness, you must be logged in to view member resources. If you do not have an account please register as a Member.",
  };

  return (
    <>
      <CssBaseline />
      <AltBanner {...altBannerProps} />
      <Stack
        sx={{
          m: { xs: "32px 15px", md: " 50px 80px" },
        }}
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 3, md: 20 }}
      >
        <Title
          title={"Member Resources & Tools"}
          subTitle={
            "The Ghana Association of Medical Lab Scientist represents leading medical laboratories that deliver essential diagnostic information to patients and health care providers. GAMLS’s membership provides members with access to innovative tools, trainings and industry-wide collaboration."
          }
          underline
        />

        <Card
          sx={{
            p: { xs: 3, md: 0 },
            flexBasis: { xs: "70%", md: "70%" },
            borderRadius: 4,
            boxShadow: 2,
          }}
        >
          <LoginContent {...loginProps} />
        </Card>
      </Stack>
    </>
  );
};
