import { RWebShare } from "react-web-share";
import { Avatar, IconButton } from "@mui/material";
import { ShareOutlined } from "../assets";

const WebShareButton = ({ url, title, text }) => {
  return (
    <div>
      <RWebShare
        data={{
          text: text,
          url: url,
          title: title,
        }}
      >
        <IconButton
          sx={{ p: 0 }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Avatar
            variant={"circular"}
            sx={{
              width: 32,
              height: 32,
              bgcolor: "white",
              color: "#7A040A",
            }}
          >
            <ShareOutlined />
          </Avatar>
        </IconButton>
      </RWebShare>
    </div>
  );
};

export default WebShareButton;
