import React from "react";
import { CustomModal } from "../../components";
import { useNavigate } from "react-router-dom";
import { sendPasswordReset } from "../../data/firebase";
import { passResetSteps } from "../../constants";

const PasswordResetModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      item={passResetSteps}
      action={(data) => sendPasswordReset(data.email, navigate)}
    />
  );
};

export default PasswordResetModal;
