import { CssBaseline } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateEvents } from "../../data";
import { Content } from "./eventselements";

const Events = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(updateEvents());
    }, 2000);

    return () => clearTimeout(getData);
  }, [dispatch]);
  return (
    <>
      <CssBaseline />
      <Content />
    </>
  );
};
export default Events;
