import { Box, Divider, Stack, Typography } from "@mui/material";
import { BannerBg } from "../assets";
import { PrimaryButton } from "./buttons";
import { useNavigate } from "react-router-dom";

const bannerStyle = {
  backgroundImage: `url(${BannerBg})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
};

export const AltBanner = ({ title, subTitle, otherTitle, color }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        background: color,
        p: { xs: "32px 15px", md: "30px 80px" },
        color: "black",
      }}
    >
      <Stack
        spacing={{ xs: 2, md: 10 }}
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "start", md: "center" }}
      >
        <Typography
          fontWeight={"bolder"}
          sx={{
            fontSize: { xs: 18, md: 40 },
            flexBasis: { xs: "100%", md: "70%" },
          }}
        >
          {title}
        </Typography>
        <Typography sx={{ fontSize: { xs: 12, md: 20 } }}>
          {otherTitle}
        </Typography>
        <Box>
          <PrimaryButton onClick={() => navigate("/login?reg_stg=0")}>
            Register
          </PrimaryButton>
        </Box>
      </Stack>
    </Box>
  );
};

export default AltBanner;
