import { Container, Stack, Typography, Box, Button } from "@mui/material";
import { NoData, UnderConstructionImg } from "../assets";

const UnderConstruction = ({ error }) => {
  return (
    <Container
      sx={{
        py: 2,
      }}
    >
      <Stack spacing={1} m={1}>
        <Typography
          variant={"h3"}
          textAlign={"center"}
          color={"primary"}
          fontWeight={"bold"}
        >
          {error ? "Sorry!" : "Oops!"}
        </Typography>
        <Typography variant={"h5"} textAlign={"center"} color={"primary"}>
          {error
            ? "Something happened while showing this page"
            : "Page under construction"}
        </Typography>
        <Typography variant={"body1"} textAlign={"center"} color={"primary"}>
          {error
            ? "Click on the button below to refresh the page"
            : "Try in a while, we are working on something beautiful for you"}
        </Typography>
      </Stack>
      <Stack flex={1} spacing={3}>
        <Box
          component={"img"}
          src={error ? NoData : UnderConstructionImg}
          sx={{
            height: { xs: 300, sm: 300, md: 400, lg: 400, xl: 700 },
          }}
          loading={"lazy"}
        />
        {error && (
          <Button onClick={() => (window.location.href = "/")}>
            {"Refesh Page"}
          </Button>
        )}
      </Stack>
    </Container>
  );
};

export default UnderConstruction;
