class EventModel {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.id = obj.id != null ? obj.id : "";
    this.type = obj.type != null ? obj.type : "";
    this.title = obj.title != null ? obj.title : "";
    this.img_url = obj.img_url != null ? obj.img_url : "";
    this.medium = obj.medium != null ? obj.medium : "";
    this.location = obj.location != null ? obj.location : "";
    this.location_url = obj.location_url != null ? obj.location_url : "";
    this.from = obj.from != null ? obj.from : "";
    this.to = obj.to != null ? obj.to : "";
    this.desc = obj.desc != null ? obj.desc : "";
    this.date = obj.date != null ? obj.date : "";
    this.registrations = obj.registrations != null ? obj.registrations : [];
    this.disabled = obj.disabled != null ? obj.disabled : false;
  }
}

export const eventConverter = {
  toFirestore: (event) => {
    return {
      id: event.id,
      type: event.type,
      title: event.title,
      img_url: event.img_url,
      medium: event.medium,
      location: event.location,
      location_url: event.location_url,
      from: event.from,
      to: event.to,
      desc: event.desc,
      date: event.date,
      disabled: event.disabled,
      registrations: event.registrations ?? [],
    };
  },
  fromFirestore: (snapshot, options) => {
    const event = snapshot.data(options);
    return new EventModel({
      id: event.id,
      type: event.type,
      title: event.title,
      img_url: event.img_url,
      medium: event.medium,
      location: event.location,
      location_url: event.location_url,
      from: event.from,
      disabled: event.disabled,
      to: event.to,
      desc: event.desc,
      registrations: event.registrations ?? [],
      date: event?.date?.seconds
        ? new Date(event?.date?.seconds * 1000).toLocaleDateString()
        : event?.date,
    });
  },
};

export default EventModel;
