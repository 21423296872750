import { CustomSelector } from "../components/index";
import { Box, Stack, Paper, Button } from "@mui/material";
import React, { useCallback, useState } from "react";

const FilterTab = ({
  queryParam1,
  queryParam2,
  queryParam3,
  onComplete,
  disabled,
}) => {
  const [params, setParams] = useState([]);

  const handleClick = useCallback(() => {
    onComplete(params);
  }, [params, onComplete]);

  return (
    <Box
      flex={1}
      sx={{
        display: "flex",
        py: { xs: 1, md: 6 },
      }}
    >
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          borderRadius: "20px",
          py: { xs: 2, md: "24px" },
          px: { xs: 2, md: "40px" },
          bgcolor: disabled ? "#cecece" : "#F30813",
          minWidth: "100%",
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          flexGrow={1}
          justifyContent={"space-between"}
          alignItems={"center"}
          spacing={4}
        >
          <CustomSelector
            values={queryParam1.params}
            label={queryParam1.label}
            disabled={disabled}
            onSelect={(e) => setParams((p) => [...p, e])}
            secondary
          />
          <CustomSelector
            values={queryParam2.params}
            label={queryParam2.label}
            disabled={disabled}
            onSelect={(e) => setParams((p) => [...p, e])}
            secondary
          />
          <CustomSelector
            values={queryParam3.params}
            label={queryParam3.label}
            onSelect={(e) => setParams((p) => [...p, e])}
            disabled={disabled}
            secondary
          />
          <Button
            variant={"contained"}
            disabled={disabled}
            onClick={handleClick}
            sx={{
              width: "16rem",
              bgcolor: "white",
              color: "black",
              borderRadius: 3,
              whiteSpace: "nowrap",
              px: 6,
            }}
          >
            {"Filter"}
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
};

export default FilterTab;
