import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "../../constants/appConstants";

const initialState = {
  lessons: [],
  loading: false,
};

export const learningSlice = createSlice({
  name: reducers.lessons,
  initialState,
  reducers: {
    learningRequest(state) {
      state.loading = true;
    },
    initializeLessons: (state, action) => {
      state.lessons = action.payload;
      state.loading = false;
    },
    updateData: (state, action) => {
      state.lessons = [...state.lessons, action.payload];
      state.loading = false;
    },
    clearLessons: (state) => {
      state.lessons = [];
      state.loading = false;
    },
    lessonsFail: (state) => {
      state.loading = false;
    },
  },
});

export const {
  lessonsFail,
  initializeLessons,
  clearLessons,
  updateData,
  learningRequest,
} = learningSlice.actions;

export default learningSlice;
