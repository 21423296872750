import { Box, Card, CardMedia, Stack } from "@mui/material";
import Image from "mui-image";
import React, { useState } from "react";
import { Candidate, FingerPrint } from "../assets";
import Title from "./title";

const FingerPrintBox = () => {
  const [showFinger, setShowFinger] = useState(false);

  return (
    <Box
      sx={{
        borderRadius: 5,
        border: "3px solid #FD0113",
        width: { xs: "60px", md: "200px" },
        height: { xs: "70px", md: "230px" },
        p: { xs: 1, md: 2 },
      }}
      onClick={() => {
        setShowFinger((prev) => !prev);
      }}
    >
      {showFinger && <Image src={FingerPrint} />}
    </Box>
  );
};

const Radio = () => {
  return (
    <Box flexGrow={1}>
      <Stack
        direction={"row"}
        flexGrow={1}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Card
          sx={{
            borderRadius: { xs: 5, md: 10 },
            width: { xs: "60px", md: "230px" },
            height: { xs: "70px", md: "230px" },
          }}
        >
          <CardMedia component="img" alt="candidate image" image={Candidate} loading={"lazy"}/>
        </Card>

        <Title title={"Dr Abu Rahmani"} />

        <FingerPrintBox />
      </Stack>
    </Box>
  );
};

const ElectionRadio = () => {
  return (
    <Stack spacing={4}>
      {[1, 2, 3, 4].map((radio, idx) => {
        return (
          <React.Fragment key={idx}>
            <Radio />
          </React.Fragment>
        );
      })}
    </Stack>
  );
};
export default ElectionRadio;
