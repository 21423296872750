import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { notificationsConverter } from "../models/notificationModel";
import { saveData } from "./notificationSlice";

const getNotifications = () => {
  return async (dispatch) => {
    onSnapshot(
      collection(db, "cms/notifications/general").withConverter(
        notificationsConverter
      ),
      (querySnapshot) => {
        const list = [];
        querySnapshot.forEach((doc) => {
          list.push({ ...doc.data(), id: doc.id });
        });

        dispatch(saveData(list));
        // console.log("hh", list);
      }
    );
    // } catch {}
  };
};

export { getNotifications };
